import { Text, Image, Divider, VStack, HStack } from '@chakra-ui/react'
import { buildAssetUrlString } from '@!/Util'
import StoreCoinSvg from '@images/store_coin.svg'

export const PaymentHeader = ({ coin, price }) => (
  <VStack w="100%" spacing="0">
    <HStack w="100%" px="4" py="5" justify="space-between">
      <Text fontWeight="bold">購入コイン数</Text>
      <HStack>
        <Image src={buildAssetUrlString(StoreCoinSvg)} w="18px" alt="コイン" />
        <Text>{coin}</Text>
      </HStack>
    </HStack>
    <Divider />
    <HStack w="100%" px="4" py="5" justify="space-between">
      <Text fontWeight="bold">お支払い金額</Text>
      <Text>￥{price.toLocaleString()}</Text>
    </HStack>
    <Divider />
  </VStack>
)
