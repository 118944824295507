import { useEffect, useMemo, useState } from 'react'
import { useAtom } from 'jotai'
import { useSearchParams } from 'react-router-dom'
import { useAnnouncementDetail } from '@!/Hooks'
import { breadcrumbAtom } from '@!/atoms/breadcrumbAtom'
import { useNavigate } from 'react-router-dom'
import { CircularProgressModal } from '@!/components/Modal'
import AnnouncementContent from '@!/Announcements/components/AnnouncementContent'

const AnnouncementDetail = () => {
  const [searchParams] = useSearchParams()
  const [announcementId, setAnnouncementId] = useState(null)
  const { data: detail, isLoading } = useAnnouncementDetail(announcementId)
  const [, setBreadcrumb] = useAtom(breadcrumbAtom)
  const startsAt = useMemo(() => {
    if (!detail) return
    return new Date(detail.starts_at)
  }, [detail])

  const navigate = useNavigate()
  const handlePageBack = () => {
    navigate(-1)
  }

  useEffect(() => {
    if (!searchParams.get('id')) return
    setAnnouncementId(searchParams.get('id'))
  }, [searchParams])

  useEffect(() => {
    if (!detail) return

    setBreadcrumb([{ name: 'マンガBANGブックス', ref: '/' }, { name: 'お知らせ', ref: '/announcements' }, { name: detail.title }])
  }, [detail])

  return (
    <>
      <CircularProgressModal isOpen={isLoading} />
      {detail && startsAt && <AnnouncementContent title={detail.title} startsAt={startsAt} message={detail.message} onPageBack={handlePageBack} />}
    </>
  )
}

export default AnnouncementDetail
