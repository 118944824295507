import { useState, useMemo, useEffect } from 'react'
import { useAtom } from 'jotai'
import { useNavigate } from 'react-router-dom'
import { CircularProgressModal, ConfirmPopupModal } from '@!/components/Modal'
import { useValidListItemCheck } from '@!/User/Payment/Hooks/useValidListItemCheck'
import { paymentListAtom, newCardTokenAtom, cardInfoAtom, newCardInfoAtom, listIndexAtom, apiTimeAtom, initPaymentAtom } from '@!/atoms/paymentAtom'
import { usePaymentSaveCard, usePaymentExecPurchase, useJumpLastUrl } from '@!/Hooks'
import { isBlank } from '@!/Util'
import { Box } from '@chakra-ui/react'
import { PageBackLink, PaymentForm } from '@!/User/Payment/components'

export const PaymentConfirm = () => {
  const jumpLastUrl = useJumpLastUrl()
  const navigate = useNavigate()
  const PurchaseStatus = {
    IDLE: null,
    NOT_COMPLETE: 0,
    COMPLETE: 1,
  }

  const [listIndex] = useAtom(listIndexAtom)
  const [paymentList] = useAtom(paymentListAtom)
  const [cardInfo] = useAtom(cardInfoAtom)
  const [newCardToken] = useAtom(newCardTokenAtom)
  const [newCardInfo] = useAtom(newCardInfoAtom)
  const [apiTime] = useAtom(apiTimeAtom)
  const [, initPayment] = useAtom(initPaymentAtom)
  const [purchaseStatus, setPurchaseStatus] = useState(PaymentConfirm.IDLE)
  const [isProcessing, setIsProcessing] = useState(false)
  const initApiError = { error: null, errorInfo: null }
  const [apiError, setApiError] = useState(initApiError)

  const [isInValidPaymentListItem] = useValidListItemCheck()
  const coin = useMemo(() => {
    if (isInValidPaymentListItem) return 0
    return paymentList[listIndex][0]
  }, [isInValidPaymentListItem, paymentList, listIndex])
  const price = useMemo(() => {
    if (isInValidPaymentListItem) return 0
    return paymentList[listIndex][1]
  }, [isInValidPaymentListItem, paymentList, listIndex])

  useEffect(() => {
    if (isInValidPaymentListItem) goPurchaseTop()
  }, [isInValidPaymentListItem])

  const [saveCardMutation, saveCardApiError] = usePaymentSaveCard()
  const [execPurchaseMutation, execPurchaseApiError] = usePaymentExecPurchase()

  // save_card APIの実行結果後処理
  useEffect(() => {
    if (!saveCardMutation.isSuccess) return

    setApiError(initApiError)
    execPurchaseMutation.mutate({ listIndex: listIndex, apiTime: apiTime })
  }, [saveCardMutation.isSuccess])

  useEffect(() => {
    if (!saveCardMutation.isError) return

    setApiError(saveCardApiError)
    setIsProcessing(false)
  }, [saveCardMutation.isError, saveCardApiError])

  // exec_purchase APIの実行結果後処理
  useEffect(() => {
    if (!execPurchaseMutation.isSuccess) return

    setPurchaseStatus(PurchaseStatus.COMPLETE)
    setApiError(initApiError)
    setIsProcessing(false)
  }, [execPurchaseMutation.isSuccess])

  useEffect(() => {
    if (!execPurchaseMutation.isError) return

    setPurchaseStatus(PurchaseStatus.NOT_COMPLETE)
    setApiError(execPurchaseApiError)
    setIsProcessing(false)
  }, [execPurchaseMutation.isError, execPurchaseApiError])

  const handleSubmit = () => {
    if (isProcessing) return

    setIsProcessing(true)

    if (newCardToken) {
      const update = !isBlank(cardInfo) ? true : false
      saveCardMutation.mutate({ token: newCardToken, isUpdate: update })
      return
    }

    execPurchaseMutation.mutate({ listIndex: listIndex, apiTime: apiTime })
  }

  const handleComplete = () => {
    initPayment()
    setPurchaseStatus(PaymentConfirm.IDLE)
    jumpLastUrl()
  }

  const goPurchaseTop = () => {
    initPayment()
    setPurchaseStatus(PaymentConfirm.IDLE)
  }

  const handleChangeCard = () => {
    navigate('/new_card')
  }

  const pageBack = (e) => {
    e.preventDefault()
    navigate(-1)
  }

  const apiErrorText = useMemo(() => {
    if (apiError.error === null) return ''

    if (apiError.error === 'fraudulent_registration') {
      return '登録回数が上限に達しました。時間をおいて再度お試しください。'
    } else if (apiError.error.indexOf('card_info') != -1) {
      const errorCode = apiError.errorInfo ? `(ErrorCode:${apiError.errorInfo})` : ''
      return '決済に失敗しました。入力内容をご確認ください' + errorCode
    } else if (apiError.error.indexOf('token_expire') != -1) {
      return '決済に失敗しました。再度入力をお願いします。'
    } else if (apiError.error.indexOf('coin_add') != -1) {
      return 'コイン付与に失敗しました。'
    }
    return 'エラーが発生しました。'
  }, [apiError])

  const handleApiErrorPopup = () => {
    if (apiError.error === null) return

    if (apiError.error.indexOf('token_expire') != -1) {
      goPurchaseTop()
      return
    } else if (apiError.error.indexOf('coin_add') != -1) {
      goPurchaseTop()
      return
    }

    setPurchaseStatus(PurchaseStatus.IDLE)
    setApiError(initApiError)
  }

  const currentCard = newCardToken ? newCardInfo : cardInfo

  return (
    <Box maxW="600px" m="auto">
      <PaymentForm
        coin={coin}
        price={price}
        newCardToken={newCardToken}
        currentCard={currentCard}
        handleSubmit={handleSubmit}
        onChangeCard={handleChangeCard}
      />
      <PageBackLink pageBack={pageBack} />
      <CircularProgressModal isOpen={isProcessing} />
      <ConfirmPopupModal handlePopup={handleApiErrorPopup} text={apiErrorText} isOpen={!!apiError.error} />
      <ConfirmPopupModal handlePopup={() => handleComplete()} text="お支払いが完了しました。" isOpen={purchaseStatus == PurchaseStatus.COMPLETE} />
    </Box>
  )
}

export default PaymentConfirm
