import PropTypes from 'prop-types'
import { Box, HStack, Image, Button } from '@chakra-ui/react'
import { buildAssetUrlString } from '@!/Util/buildAssetUrlString'

import StoreCoinSvg from '@images/store_coin.svg'

export const PaymentCoinListRow = ({ coin, price, attention, index, handleClickCoin }) => (
  <HStack w="100%" p="8px 16px" justify="space-between" boxSizing="border-box">
    <HStack w="calc(100%/3)" spacing={0}>
      <Box mr={1}>
        <Image w="16px" src={buildAssetUrlString(StoreCoinSvg)} />
      </Box>
      <Box fontWeight="600" lineHeight="22px">
        {coin.toLocaleString()}
      </Box>
    </HStack>
    {attention && (
      <Box w="calc(100%/3)" color="label.accent" fontSize="xs" lineHeight="13px" fontWeight="600">
        {attention}
      </Box>
    )}
    <Box w="calc(100%/3)" textAlign="right">
      <Button w="88px" bgColor="secondary.main" fontSize="sm" fontWeight="600" lineHeight="22px" onClick={() => handleClickCoin(index)}>
        ￥{price.toLocaleString()}
      </Button>
    </Box>
  </HStack>
)

PaymentCoinListRow.propTypes = {
  coin: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  attention: PropTypes.string,
  index: PropTypes.number.isRequired,
  handleClickCoin: PropTypes.func.isRequired,
}
