import { useEffect } from 'react'
import { useAtom } from 'jotai'
import { breadcrumbAtom } from '@!/atoms/breadcrumbAtom'
import { Box, Heading, Text, UnorderedList, ListItem, Table, Tbody, Tr, Td, Link } from '@chakra-ui/react'

export const FundSettlement = () => {
  const [, setBreadcrumb] = useAtom(breadcrumbAtom)

  useEffect(() => {
    setBreadcrumb([{ name: 'マンガBANGブックス', ref: '/' }, { name: '資金決済法に基づく表示' }])
  }, [])

  return (
    <Box p="4">
      <Heading as="h2" m={0} color="black" size="md">
        マンガBANGコインに関する注意事項・資金決済法に基づく表示事項
      </Heading>
      <Box color="gray.800">
        <Text fontSize="sm" my="2">
          マンガBANGにてコインを購入・使用する前に、必ずお読みください。
        </Text>

        <Heading as="h3" my="3" fontSize="md">
          1. 使用可能範囲
        </Heading>
        <Text fontSize="sm" my="2">
          マンガBANGのコインは株式会社Amaziaが運営する「マンガBANG」という名称のウェブサイト及び通信端末用アプリケーション内での電子コンテンツの購入及びレンタルの代金決済にご利用頂けます。
        </Text>

        <Heading as="h3" my="3" fontSize="md">
          2. 有効期間または期限
        </Heading>
        <Text fontSize="sm" my="2">
          通信端末用アプリケーションで購入したコイン：有効期限の設定はありません
          <br />
          上記以外のウェブサイトで購入したコイン：コインを取得した日から180日
          <br />
          <br />
          なお、ユーザーが通信端末用アプリケーション「マンガBANG」のアンインストール等にてマンガBANGの利用資格を喪失した場合は、未使用分のコインは消滅するものとします。
        </Text>

        <Heading as="h3" my="3" fontSize="md">
          3. 支払可能金額等
        </Heading>
        <Text fontSize="sm" my="2">
          支払可能金額等の設定はありません。
        </Text>

        <Heading as="h3" my="3" fontSize="md">
          4. 利用上の注意
        </Heading>
        <UnorderedList pl="18px" fontSize="sm">
          <ListItem>
            通信端末用アプリケーション「マンガBANG」で購入したコインは当該通信端末用アプリケーション以外ではご利用できません。通信端末用アプリケーションとウェブサイトで購入されたコインの残高は区分して管理されます。
          </ListItem>
          <ListItem>電子コンテンツの表示価格は、消費税込みのものとなっております。</ListItem>
          <ListItem>コイン購入後のお支払い代金の返金及びコイン消費後のコイン返還・返金は行っておりませんので、予めご了承ください。</ListItem>
        </UnorderedList>

        <Heading as="h3" my="3" fontSize="md">
          5. 未使用残高の確認方法
        </Heading>
        <Text fontSize="sm" my="2">
          コインの未使用残高は、メニュー画面で確認頂くことが可能です。
        </Text>

        <Heading as="h3" my="3" fontSize="md">
          6. サービス提供者
        </Heading>
        <Text fontSize="sm" my="2">
          株式会社Amazia
          <br />
          〒150-0031
          <br />
          東京都渋谷区桜丘町1番2号 渋谷サクラステージセントラルビル13F
        </Text>

        <Heading as="h3" my="3" fontSize="md">
          7. お問い合わせ先
        </Heading>
        <Text fontSize="sm" my="2">
          マンガBANG運営事務局
        </Text>
        <Table size="sm">
          <Tbody>
            <Tr>
              <Td>メールアドレス</Td>
              <Td whiteSpace="nowrap">info@support-mangabang.jp</Td>
            </Tr>
            <Tr>
              <Td>電話番号</Td>
              <Td>
                03-6415-3437
                <br />
                (平日10:00～17:00)
              </Td>
            </Tr>
          </Tbody>
        </Table>
        <Text fontSize="sm" my="2">
          ※土日祝日に頂いたお問い合わせに関しては翌営業日のご対応となりますので予めご了承ください。
          <br />
          ※お問い合わせへの回答は原則メールにて行っております。何卒ご協力お願いいたします。
        </Text>

        <Heading as="h3" my="3" fontSize="md">
          8. マンガBANG利用規約
        </Heading>
        <Text fontSize="sm" my="2">
          <Link href="/static_page/terms">マンガBANG利用規約参照</Link>
        </Text>

        <Heading as="h3" my="3" fontSize="md">
          9. 前払式支払手段の未使用残高の保全
        </Heading>
        <Text fontSize="sm" my="2">
          当社は、資金決済法の以下の定めに基づき、金銭を供託することにより、前払式支払手段にかかる未使用残高を保全しております。
        </Text>
        <UnorderedList pl="18px" fontSize="sm">
          <ListItem>
            資金決済法第14条第1項の規定の趣旨：
            <br />
            前払式支払手段の保有者の保護のための制度として、資金決済に関する法律の規定に基づき、前払式支払手段の毎年3月31日および9月30日現在の未使用残高の半額以上の額の発行保証金を法務局等に供託等することにより資産保全することが義務づけられております。
          </ListItem>
          <ListItem>
            資金決済法第31条第1項に規定する権利の内容：
            <br />
            万が一の場合、前払式支払手段の保有者は、資金決済に関する法律第31条の規定に基づき、あらかじめ保全された発行保証金について、他の債権者に先立ち弁済を受けることができます。
          </ListItem>
        </UnorderedList>

        <Heading as="h3" my="3" fontSize="md">
          10. 不正取引による損失の補償等に関する対応方針
        </Heading>
        <UnorderedList pl="18px" fontSize="sm">
          <ListItem>
            当社は、前払式支払手段の不正取引により、前払式支払手段の保有者に生じた損失について、利用規約に例外として定める場合を除き、一切の責任を負いません。
          </ListItem>
          <ListItem>
            当社が発行する前払式支払手段と連携する、他社が提供する各種決済サービス（クレジットカードその他の決済手段。以下「連携サービス」といいます）を介した不正取引により発生した損失について、当社は責任を負いません。各連携サービスにおいて生じた損害の補償については当該連携サービスの提供元へとお問い合わせください。但し、当該連携サービスの提供元が補償を行うことを保証するものではありません。
          </ListItem>
        </UnorderedList>
      </Box>
    </Box>
  )
}
