import { PropTypes } from 'prop-types'

import { LinkBox, LinkOverlay, HStack, Spacer } from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'

export const FooterMenuItem = (props) => {
  const { url, target, children } = props

  return (
    <LinkBox py={3} width={'100%'}>
      <HStack>
        <LinkOverlay fontSize="2xs" fontWeight="normal" color="#222" href={url} ml={3} target={target}>
          {children}
        </LinkOverlay>
        <Spacer />
        <ChevronRightIcon color="#aaa" mr={3} />
      </HStack>
    </LinkBox>
  )
}

FooterMenuItem.propTypes = {
  url: PropTypes.string.isRequired,
  target: PropTypes.string,
}

FooterMenuItem.defaultProps = {
  target: '',
}
