import { useQuery } from '@tanstack/react-query'

import { userPointKeys } from '@!/Api/queryKeys'
import { availablePointApi } from '@!/Api/user'

const usePointList = (userHash) => {
  const {
    isLoading,
    isError,
    data: pointList,
  } = useQuery({
    queryKey: userPointKeys.availablePoints(userHash),
    queryFn: async () => {
      if (userHash) {
        return await availablePointApi()
      }

      // NOTE: userHashが設定されていない場合はAPIを呼び出さない
      return []
    },
  })
  return { isLoading, isError, pointList }
}

export default usePointList
