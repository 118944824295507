import { useEffect, useRef, useState } from 'react'
import { useAtom } from 'jotai'
import { useNavigate } from 'react-router-dom'
import { checkPassword } from '@!/Util/string'
import { CircularProgressModal } from '@!/components/Modal'
import { breadcrumbAtom } from '@!/atoms/breadcrumbAtom'
import { useUpdatePassword } from '@!/Hooks'
import { alertMessageAtom } from '@!/atoms/appSettingsAtom'
import { Container, VStack, Heading, Box, Text, FormControl, FormLabel, FormErrorMessage, Input } from '@chakra-ui/react'
import { PrimaryButton } from '@!/components/Button'
import { isBlank } from '@!/Util'

const AccountUpdatePassword = () => {
  const navigate = useNavigate()
  const [updatePasswordMutation, registrationStatus, updatePasswordApiError] = useUpdatePassword()
  const [validate, setValidate] = useState({})
  const [, setAlertMessage] = useAtom(alertMessageAtom)

  const currentPasswordRef = useRef(null)
  const newPasswordRef = useRef(null)
  const confirmPasswordRef = useRef(null)
  const [, setBreadcrumb] = useAtom(breadcrumbAtom)

  useEffect(() => {
    setBreadcrumb([{ name: 'マンガBANGブックス', ref: '/' }, { name: 'パスワード再設定' }])
  }, [])

  useEffect(() => {
    if (registrationStatus === 'success') {
      setAlertMessage('パスワードを変更しました。')
      navigate('/user/account')
    }
  }, [registrationStatus])

  useEffect(() => {
    if (updatePasswordApiError === 'INVALID_PASSWORD') {
      setValidate({ currentPassword: '現在のパスワードが正しくありません。' })
    }
  }, [updatePasswordApiError])

  const update = (e) => {
    e.preventDefault()

    setValidate({})

    const currentPassword = currentPasswordRef.current.value
    const newPassword = newPasswordRef.current.value
    const confirmPassword = confirmPasswordRef.current.value

    let errors = {}

    if (!checkPassword(currentPassword)) {
      errors.currentPassword = '現在のパスワードが正しくありません。'
    }

    if (!checkPassword(newPassword)) {
      errors.newPassword = '新しいパスワードが正しくありません。\nパスワードは半角英数字8文字以上かつ英数字それぞれ1文字以上のものを入力してください。'
    }

    if (newPassword !== confirmPassword) {
      errors.confirmPassword = 'パスワードと確認用のパスワードが一致しません。'
    }

    if (!isBlank(errors)) {
      setValidate(errors)
      return
    }

    updatePasswordMutation.mutate({ currentPassword: currentPassword, newPassword: newPassword })
  }

  const isError = (field) => !!validate[field]
  const errorMessage = (field) => {
    if (isError(field)) {
      return <FormErrorMessage whiteSpace="pre-line">{validate[field]}</FormErrorMessage>
    }
    return null
  }

  return (
    <Container p={5} bg="bg.primary" boxSizing="border-box">
      <Box my={8}>
        <Heading size="md" as="h2" textAlign="center" my={3}>
          パスワード変更
        </Heading>
        <CircularProgressModal isOpen={updatePasswordMutation.isPending} />
        <form onSubmit={update}>
          <VStack spacing={0}>
            <FormControl isRequired isInvalid={isError('currentPassword')} my={3}>
              <FormLabel fontWeight="bold" fontSize="sm">
                現在のパスワード
              </FormLabel>
              <Input type="password" ref={currentPasswordRef} placeholder="現在のパスワード" bg="bg.secondary" boxSizing="border-box" />
              {errorMessage('currentPassword')}
            </FormControl>
            <FormControl isRequired isInvalid={isError('newPassword')} my={3}>
              <FormLabel fontWeight="bold" fontSize="sm">
                新しいパスワード
              </FormLabel>
              <Input type="password" ref={newPasswordRef} placeholder="新しいパスワード" bg="bg.secondary" boxSizing="border-box" />
              <Text fontSize="2xs" color="label.secondary" mt={1}>
                半角英数字8文字以上
              </Text>
              {errorMessage('newPassword')}
            </FormControl>
            <FormControl isRequired isInvalid={isError('confirmPassword')} my={3}>
              <FormLabel fontWeight="bold" fontSize="sm">
                新しいパスワード(確認)
              </FormLabel>
              <Input type="password" ref={confirmPasswordRef} placeholder="新しいパスワード(確認)" bg="bg.secondary" boxSizing="border-box" />
              {errorMessage('confirmPassword')}
            </FormControl>
            {registrationStatus === 'fail' && (
              <Text color="label.accent" fontSize="sm" my={3}>
                パスワードの変更に失敗しました。
              </Text>
            )}
            <PrimaryButton type="submit" my={3}>
              変更
            </PrimaryButton>
          </VStack>
        </form>
      </Box>
    </Container>
  )
}

export default AccountUpdatePassword
