import React from 'react'
import { CoverImage } from '@!/Store/Common/components'
import { Box, Text, Flex, Divider } from '@chakra-ui/react'
import { AuthorList } from '@!/components/List'

// リスト表示
export default function BookshelfList({ books, handleClickBook }) {
  return (
    <>
      {books.map((book) => (
        <Box key={book.distribution_id}>
          <Flex cursor="pointer" onClick={() => handleClickBook(book.distribution_id)}>
            <Box mr={4} width="90px">
              <CoverImage src={book.image_url} alt={book.title_name} width="100%" />
            </Box>
            <Box flex={1}>
              <Text fontWeight="bold" color="label.primary">
                {book.title_name}
              </Text>
              {book.author_name && (
                <Box my={2}>
                  <Text fontSize="3xs">
                    著者：
                    <AuthorList authors={book.author_name} />
                  </Text>
                </Box>
              )}
            </Box>
          </Flex>
          <Divider my={4} />
        </Box>
      ))}
    </>
  )
}
