import { extendTheme } from '@chakra-ui/react'

const colors = {
  primaryAccent: 'white',
  link: '#2a8de8',
  label: {
    primary: '#1D1D21',
    secondary: '#5D5D61',
    tertiary: '#B8B8BD',
    white: '#FFFFFF',
    black: '#000000',
    link: '#2A8DE8',
    accent: '#E94841',
    line: '#52FF94',
  },
  bg: {
    primary: '#FFFFFF',
    secondary: '#F3F3F8',
    tertiary: '#EBEBF0',
    footer: '#222222',
    pointBackTag: '#029CF2',
    guide: '#B02D23',
    guideAccent: '#FCD502',
    guideSubAccent: '#D2352A',
    blueGray: '#4C636E',
  },
  divider: {
    dark: '#B8B8BD',
    light: '#DCDCE1',
    white: '#FFFFFF',
  },
  primary: {
    main: '#FD4238',
    50: '#FFEBEF',
    100: '#FFCED3',
    200: '#F49B9C',
    300: '#EC7475',
    400: '#F75352',
    500: '#FD4238',
    600: '#EE3737',
    700: '#DC2D31',
    800: '#CF252A',
    900: '#C0161D',
  },
  secondary: {
    main: '#FEC300',
    50: '#FFF8E1',
    100: '#FEEDB3',
    200: '#FEE182',
    300: '#FED64E',
    400: '#FECC26',
    500: '#FEC300',
    600: '#FEB500',
    700: '#FEA200',
    800: '#FF9100',
    900: '#FF7100',
  },
  // override default color
  gray: {
    50: '#F9F9FE',
    100: '#F3F3F8',
    200: '#EBEBF0',
    300: '#EDEDED',
    400: '#B8B8BD',
    500: '#99999E',
    600: '#707075',
    700: '#5D5D61',
    800: '#3E3E42',
    900: '#1D1D21',
  },
  social: {
    facebook: '#1877f2',
    x: '#1DA1F2',
    line: '#00C300',
  },
}

const sizes = {
  container: {
    md: '800px',
  },
}

const styles = {
  global: {
    'html, body': {
      height: '100%',
      bg: 'gray.50',
      color: 'gray.900',
    },

    '#__next': {
      height: '100%',
      bg: 'gray.50',
    },
  },
}

const components = {
  Link: {
    baseStyle: {
      color: 'link',
      textDecoration: 'underline',
      fontWeight: 'bold',
      _hover: {
        textDecoration: 'none',
      },
    },
  },
  Table: {
    variants: {
      simple: {
        thead: {
          borderTop: '1px solid #DCDCE1',
        },
        th: {
          color: 'label.primary',
          fontSize: 'sm',
          fontWeight: 'bold',
          bg: 'bg.secondary',
          borderColor: 'divider.light',
          borderRight: '1px solid #DCDCE1', // divider.light
          '&:last-child': {
            borderRight: 'none',
          },
        },
        td: {
          fontSize: 'sm',
          fontWeight: 'bold',
          borderColor: 'divider.light',
          borderRight: '1px solid #DCDCE1', // divider.light
          '&:last-child': {
            borderRight: 'none',
          },
        },
      },
    },
  },
  Divider: {
    baseStyle: {
      borderColor: 'divider.light',
    },
  },
  StackDivider: {
    baseStyle: {
      borderColor: 'divider.light',
    },
  },
  Button: {
    variants: {
      solid: {
        borderRadius: '4px',
        boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.10)',
      },
      outline: {
        borderRadius: '4px',
        boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.10)',
      },
      ghost: {
        border: 'none',
      },
    },
  },
}

const fontSizes = {
  '3xs': '11px',
  '2xs': '12px',
  xs: '13px',
  sm: '14px',
  md: '16px',
  lg: '18px',
  xl: '20px',
  '2xl': '24px',
  '3xl': '32px',
  '4xl': '36px',
  '5xl': '42px',
}

export const theme = extendTheme({ colors, styles, components, sizes, fontSizes })
