import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Box, Text, Menu, MenuButton, MenuList, MenuItem, Divider } from '@chakra-ui/react'
import CustomRadio from '@!/Store/Common/components/CustomRadio'
import { CircularProgressModal } from '@!/components/Modal'
import { useCoupons } from '@!/Hooks'
import { IoIosArrowDown } from 'react-icons/io'
import { useAtom } from 'jotai'
import { userHashAtom } from '@!/atoms/userInfoAtom'

const CouponForm = ({ setIsCouponApplied, setCurrentCoupon, setPointValue, setSpendPoints, basePrice }) => {
  const [userHash] = useAtom(userHashAtom)
  const { data: couponsData, isLoading } = useCoupons(userHash)
  const noCoupon = { name: 'クーポンを利用しない', code: null }
  const coupons = couponsData ? [noCoupon, ...couponsData] : [noCoupon]
  const [selectedCoupon, setSelectedCoupon] = useState(coupons[0])

  const handleCouponSelect = (selectedCode) => {
    // クーポン選択前に入力されたポイントはリセット
    setPointValue('')
    setSpendPoints(false)

    const selectedCoupon = coupons.find((coupon) => coupon.code === selectedCode) || noCoupon

    if (!selectedCode) {
      setIsCouponApplied(false)
      setCurrentCoupon({})
    } else {
      setIsCouponApplied(true)
      setCurrentCoupon(selectedCoupon)
    }

    setSelectedCoupon(selectedCoupon)
  }

  return (
    <>
      <Menu>
        <MenuButton
          as={Button}
          isDisabled={basePrice === 0}
          position="relative"
          rightIcon={
            <Box position="absolute" right={0} top={0} bottom={0} bgColor="bg.secondary" display="flex" alignItems="center" px={4}>
              <IoIosArrowDown />
            </Box>
          }
          w="100%"
          h="42px"
          bgColor="bg.primary"
          border="solid 1px #E0E0E0"
          boxShadow="none"
          borderRadius="sm"
          fontSize="sm"
          color="label.primary"
          textAlign="left"
          _hover="none"
          _active="none"
        >
          <Text as="span" color={selectedCoupon.code ? 'label.accent' : 'label.primary'}>
            {selectedCoupon.name}
          </Text>
        </MenuButton>
        <MenuList p={0}>
          {coupons.map((coupon) => {
            const isDisabled = basePrice < coupon.min_purchase_amount
            const isSelected = selectedCoupon.code === coupon.code
            return (
              <Box key={coupon.code}>
                <MenuItem code={coupon.code} onClick={() => handleCouponSelect(coupon.code)} isDisabled={isDisabled} bg={isSelected ? 'gray.100' : 'white'}>
                  <Box display="flex" alignItems="center">
                    <CustomRadio isChecked={isSelected} />
                    <Box px={2}>
                      <Text fontWeight="black">{coupon.name}</Text>
                      {!isDisabled && coupon.requirements && <Text fontSize="3xs">利用条件：{coupon.requirements}</Text>}
                      {coupon.expire_date && <Text fontSize="3xs">有効期限：{coupon.expire_date}</Text>}
                      {isDisabled && coupon.min_purchase_amount && (
                        <Text fontSize="3xs" color="label.accent">
                          ※{coupon.min_purchase_amount}円以上ご購入の場合にご利用いただけます
                        </Text>
                      )}
                    </Box>
                  </Box>
                </MenuItem>
                <Divider />
              </Box>
            )
          })}
        </MenuList>
      </Menu>
      <CircularProgressModal isOpen={isLoading} />
    </>
  )
}

CouponForm.propTypes = {
  setIsCouponApplied: PropTypes.func.isRequired,
  setCurrentCoupon: PropTypes.func.isRequired,
  setPointValue: PropTypes.func.isRequired,
  setSpendPoints: PropTypes.func.isRequired,
}

export default CouponForm
