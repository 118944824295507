import { useQuery } from '@tanstack/react-query'
import { userAccountKeys } from '@!/Api/queryKeys'
import { getAccountInfoApi } from '@!/Api/user'

export const useAccountInfo = (userHash) => {
  return useQuery({
    queryKey: userAccountKeys.accountInfo(userHash),
    queryFn: async () => {
      return await getAccountInfoApi()
    },
    enabled: !!userHash,
  })
}
