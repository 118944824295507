import { useQueryClient, useMutation } from '@tanstack/react-query'

import { updateCartItemsApi } from '@!/Api/cart'
import { cartItemsKeys } from '@!/Api/queryKeys'

export function useUpdateCartItems(userHash) {
  const queryClient = useQueryClient()

  const updateCartItemsMutation = useMutation({
    mutationFn: () => updateCartItemsApi(),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: cartItemsKeys.cartItemsLoggedIn(userHash) })
    },
    onError: (error) => {
      // 更新に失敗したらログを出力して後続の処理を実行
      console.log(error)
    },
  })

  return updateCartItemsMutation
}
