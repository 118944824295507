import { useState, useEffect } from 'react'
import { useAtom } from 'jotai'
import { CircularProgressModal, ConfirmPopupModal, YesNoPopupModal } from '@!/components/Modal'
import { breadcrumbAtom } from '@!/atoms/breadcrumbAtom'
import { useInquirySubmit } from '@!/Inquiry/hooks/useInquirySubmit'
import InquiryForm from '@!/Inquiry/components/InquiryForm'

const Inquiry = () => {
  const inquiryMutation = useInquirySubmit()
  const [, setBreadcrumb] = useAtom(breadcrumbAtom)
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const [formData, setFormData] = useState(null)

  useEffect(() => {
    setBreadcrumb([{ name: 'マンガBANGブックス', ref: '/' }, { name: 'お問い合わせ' }])
  }, [setBreadcrumb])

  const onSubmit = (data) => {
    setFormData(data)
    setIsConfirmModalOpen(true)
  }

  const handlePost = () => {
    setIsConfirmModalOpen(false)
    inquiryMutation.mutate(formData)
  }

  const handleCloseSuccessModal = () => {
    window.location.href = '/'
  }

  const handleCloseErrorModal = () => {
    inquiryMutation.reset()
  }

  return (
    <>
      <InquiryForm onSubmit={onSubmit} isSubmitting={inquiryMutation.isPending} />
      <YesNoPopupModal
        title={'送信します。よろしいですか？'}
        handleCancel={() => setIsConfirmModalOpen(false)}
        handleSubmit={handlePost}
        cancelTitle={'キャンセル'}
        submitTitle={'送信する'}
        isOpen={isConfirmModalOpen}
      />
      <CircularProgressModal isOpen={inquiryMutation.isPending} />
      <ConfirmPopupModal text="送信しました。" handlePopup={handleCloseSuccessModal} isOpen={inquiryMutation.isSuccess} />
      <ConfirmPopupModal text="送信できませんでした。" handlePopup={handleCloseErrorModal} isOpen={inquiryMutation.isError} />
    </>
  )
}

export default Inquiry
