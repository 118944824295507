import { useState, useRef, useEffect } from 'react'
import { PropTypes } from 'prop-types'

import { checkEmailAddress } from '@!/Util/string'

import { FormControl, FormLabel, FormErrorMessage, Input, Stack } from '@chakra-ui/react'

import { PrimaryButton } from '@!/components/Button'

export const EmailLogin = ({ formErrorMessage, isDisabled, handleLogin }) => {
  const [loginError, setLoginError] = useState('')
  const emailInput = useRef('')
  const passwordInput = useRef('')

  useEffect(() => {
    setLoginError(formErrorMessage)
  }, [formErrorMessage])

  const validateEmail = (email) => {
    return checkEmailAddress(email)
  }

  const validatePassword = (password) => {
    if (password.length < 8) {
      return false
    }

    return true
  }

  const submit = (e) => {
    e.preventDefault()

    const email = emailInput.current.value
    const password = passwordInput.current.value

    if (validateEmail(email) && validatePassword(password)) {
      setLoginError('')
      handleLogin({ email: email, password: password })
    } else {
      setLoginError('メールアドレスまたはパスワードが正しくありません。')
    }
  }

  return (
    <form onSubmit={submit}>
      <Stack spacing={0}>
        <FormControl isRequired isInvalid={loginError} my={2}>
          <FormLabel fontWeight="bold">メールアドレス</FormLabel>
          <Input type="email" placeholder="example@manga-bang.com" ref={emailInput} autoComplete="email" isDisabled={isDisabled} boxSizing="border-box" />
        </FormControl>
        <FormControl isRequired isInvalid={loginError} my={2}>
          <FormLabel fontWeight="bold">パスワード</FormLabel>
          <Input type="password" placeholder="********" ref={passwordInput} autoComplete="current-password" isDisabled={isDisabled} boxSizing="border-box" />
          {loginError && <FormErrorMessage>{loginError}</FormErrorMessage>}
        </FormControl>
        <PrimaryButton variant="outline" isDisabled={isDisabled} type="submit" my={2}>
          メールアドレスでログイン
        </PrimaryButton>
      </Stack>
    </form>
  )
}

EmailLogin.propTypes = {
  formErrorMessage: PropTypes.string,
  isDisabled: PropTypes.bool,
  handleLogin: PropTypes.func.isRequired,
}
