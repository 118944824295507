import { PropTypes } from 'prop-types'

import { Box, LinkBox, LinkOverlay, Stack, Text, VStack, Flex, Link } from '@chakra-ui/react'

import { buildUrlString } from '@!/Util'
import { CoverImage, PriceLabel } from '@!/Store/Common/components'
import { PurchasedLabel } from './PurchasedLabel'
import { SampleButton } from '@!/Store/Common/components/BookList/Button'
import { AuthorList } from '@!/components/List'

export const ListItem = (props) => {
  const { title, imageUrl, authorName, publisherName, price, properPrice, sampleBookId, distributionId, rating, linkUrl, isPurchased } = props

  return (
    <LinkBox py={3}>
      <Stack direction="row" spacing={0}>
        <Box mr="0.5rem">
          <CoverImage src={imageUrl} alt={title} width="90px" />
        </Box>
        <VStack spacing={0} w="100%">
          <Box w="100%">
            <Text fontWeight="bold">
              <LinkOverlay href={linkUrl}>{title}</LinkOverlay>
            </Text>
            <Box my={2}>
              {authorName && (
                <Text fontSize="2xs">
                  著者：
                  <AuthorList authors={authorName} />
                </Text>
              )}
              {publisherName && (
                <Text fontSize="2xs">
                  出版社：
                  <Link textDecoration="none" href={`${buildUrlString(`/store/search/publisher/${publisherName}`)}`}>
                    {publisherName}
                  </Link>
                </Text>
              )}
            </Box>
            {isPurchased ? <PurchasedLabel /> : <PriceLabel price={price} properPrice={properPrice} />}
          </Box>
          <Flex w="100%" justifyContent="flex-end">
            {sampleBookId && price !== 0 && !isPurchased && (
              <Box w="124px" h="37px">
                <SampleButton distributionId={distributionId} sampleBookId={sampleBookId} rating={rating} price={price} isPurchased={isPurchased} />
              </Box>
            )}
          </Flex>
        </VStack>
      </Stack>
    </LinkBox>
  )
}

ListItem.propType = {
  title: PropTypes.string.isRequired,
  imageUrl: PropTypes.string, // エラーで画像が無いケースがある
  authorName: PropTypes.string,
  publisherName: PropTypes.string,
  price: PropTypes.number.isRequired,
  properPrice: PropTypes.number.isRequired,
  sampleBookId: PropTypes.string.isRequired,
  distributionId: PropTypes.string.isRequired,
  rating: PropTypes.number.isRequired,
  linkUrl: PropTypes.string.isRequired,
  isPurchased: PropTypes.bool,
}
