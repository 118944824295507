import { isBlank } from '@!/Util'
import { Box, Text, Heading, LinkBox, LinkOverlay, Center, Stack, StackDivider } from '@chakra-ui/react'

const AnnouncementItem = ({ data }) => {
  const startsAt = new Date(data.starts_at)
  return (
    <LinkBox>
      <LinkOverlay href={`/announcements#detail?id=${data.id}`}>{data.title}</LinkOverlay>
      <Text fontSize="3xs" color="label.secondary">
        {`${startsAt.getFullYear()}/${startsAt.getMonth() + 1}/${startsAt.getDate()}`}
      </Text>
    </LinkBox>
  )
}

const AnnouncementList = ({ announcements }) => {
  return (
    <Box p={4}>
      <Heading as="h1" size="md" py={2}>
        お知らせ
      </Heading>
      <Stack py={4} divider={<StackDivider />}>
        {!isBlank(announcements) ? (
          announcements.map((announcement) => <AnnouncementItem key={announcement.id} data={announcement} />)
        ) : (
          <Center p={4}>お知らせはありません。</Center>
        )}
      </Stack>
    </Box>
  )
}

export default AnnouncementList
