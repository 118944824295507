import { Box } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { useValidListItemCheck } from '@!/User/Payment/Hooks/useValidListItemCheck'
import { PageBackLink } from '@!/User/Payment/components'
import { useGmoPayment } from '@!/components/CreditCard/hooks/useGmoPayment'
import { CreditCardForm } from '@!/components/CreditCard/components'
import { PrimaryButton } from '@!/components/Button'

export const PaymentNewCard = () => {
  const navigate = useNavigate()
  const { handleGetGmoToken } = useGmoPayment()

  useValidListItemCheck()

  const onSubmit = async (data, setError) => {
    const result = await handleGetGmoToken(data, setError)
    if (result.success) {
      navigate('/confirm')
    }
  }

  const pageBack = (e) => {
    e.preventDefault()
    navigate(-1)
  }

  return (
    <Box maxW="600px" m="auto" pt="8">
      <Box p="4">
        <CreditCardForm onSubmit={onSubmit}>
          <PrimaryButton type="submit" fontSize="lg" mt="5">
            確認画面へ
          </PrimaryButton>
        </CreditCardForm>
      </Box>
      <PageBackLink pageBack={pageBack} />
    </Box>
  )
}

export default PaymentNewCard
