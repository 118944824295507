import { useState, useRef, useEffect } from 'react'
import { PropTypes } from 'prop-types'

import { checkEmailAddress, checkPassword } from '@!/Util/string'

import { Checkbox, FormControl, FormLabel, FormErrorMessage, FormHelperText, Input, Link, Stack, Text, VStack } from '@chakra-ui/react'

import { CenterLineText } from './Text'
import { PrimaryButton } from '@!/components/Button'
import { WarningAppRegister } from '@!/User/Login/components'

export const EmailRegistration = ({ formErrorMessage, isDisabled, handleRegistration }) => {
  const [registrationError, setRegistrationError] = useState('')
  const [acceptanceError, setAcceptanceError] = useState('')
  const emailInput = useRef('')
  const passwordInput = useRef('')
  const checkbox = useRef()

  useEffect(() => {
    setRegistrationError(formErrorMessage)
  }, [formErrorMessage])

  const validateEmail = (email) => {
    return checkEmailAddress(email)
  }

  const validatePassword = (password) => {
    return checkPassword(password)
  }

  const submit = (e) => {
    e.preventDefault()

    const email = emailInput.current.value
    const password = passwordInput.current.value

    let hasError = false
    if (!checkbox.current.checked) {
      setAcceptanceError('登録には利用規約とプライバシーポリシーへの同意が必要です。')
      hasError = true
    } else {
      setAcceptanceError('')
    }

    if (!validateEmail(email) || !validatePassword(password)) {
      setRegistrationError('メールアドレスまたはパスワードが正しくありません。')
      hasError = true
    } else {
      setRegistrationError('')
    }

    if (hasError) {
      return
    }

    handleRegistration({ email: email, password: password })
  }

  return (
    <form onSubmit={submit}>
      <Stack spacing={0}>
        <WarningAppRegister />
        <CenterLineText fontSize="sm" fontWeight="bold" my={5}>
          メールアドレスで登録する
        </CenterLineText>
        <FormControl isRequired isInvalid={registrationError} my={2}>
          <FormLabel fontWeight="bold">メールアドレス</FormLabel>
          <Input
            type="email"
            placeholder="example@manga-bang.com"
            ref={emailInput}
            isDisabled={isDisabled}
            autoComplete="email"
            bg="bg.secondary"
            boxSizing="border-box"
          />
        </FormControl>
        <FormControl isRequired isInvalid={registrationError} my={2}>
          <FormLabel fontWeight="bold">パスワード</FormLabel>
          <Input
            type="password"
            placeholder="********"
            ref={passwordInput}
            isDisabled={isDisabled}
            autoComplete="new-password"
            bg="bg.secondary"
            boxSizing="border-box"
          />
          <FormHelperText color="label.secondary">半角英数字8文字以上</FormHelperText>
          {registrationError && <FormErrorMessage>{registrationError}</FormErrorMessage>}
        </FormControl>

        <FormControl isInvalid={acceptanceError} my={2}>
          <VStack>
            <Checkbox ref={checkbox} colorScheme="gray" isDisabled={isDisabled}>
              <Text fontSize="sm">
                <Link as="a" href="/static_page/terms" isExternal>
                  利用規約
                </Link>
                と
                <Link as="a" href="/static_page/privacy_policy" isExternal>
                  プライバシーポリシー
                </Link>
                に同意する
              </Text>
            </Checkbox>
            {acceptanceError && <FormErrorMessage>{acceptanceError}</FormErrorMessage>}
          </VStack>
        </FormControl>
        <PrimaryButton variant="outline" isDisabled={isDisabled} onClick={submit} type="submit" my={2}>
          メールアドレスで会員登録
        </PrimaryButton>
        <Text fontSize="sm" my={2}>
          @support-mangabang.jp からの受信設定をしてください。
        </Text>
      </Stack>
    </form>
  )
}

EmailRegistration.propTypes = {
  formErrorMessage: PropTypes.string,
  isDisabled: PropTypes.bool,
  handleRegistration: PropTypes.func.isRequired,
}
