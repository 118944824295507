import { useEffect } from 'react'

import { PrimaryButton } from '@!/components/Button'
import { useReadBook } from '@!/Hooks'
import { storeBookColophonUrl } from '@!/Util/location'
import { CircularProgressModal, ConfirmPopupModal } from '@!/components/Modal'

const ReadBookLinkButton = ({ book }) => {
  const [readPurchasedBookMutation, redirectUrl, apiError, setApiError] = useReadBook('purchased')

  useEffect(() => {
    if (!redirectUrl) return

    window.location.href = redirectUrl
  }, [redirectUrl])

  const handleClick = () => {
    const colophonUrl = storeBookColophonUrl(book.distribution_id, false)
    readPurchasedBookMutation.mutate({ bookId: book.distribution_id, colophonUrl: colophonUrl })
  }

  const handleApiError = () => {
    console.log(apiError)
    setApiError(null)
  }

  return (
    <>
      <PrimaryButton variant="outline" width="100%" height="37px" maxW="240px" whiteSpace="normal" onClick={handleClick}>
        今すぐ読む
      </PrimaryButton>
      <CircularProgressModal isOpen={readPurchasedBookMutation.isPending} />
      <ConfirmPopupModal text="エラーが発生しました。" handlePopup={() => handleApiError()} isOpen={!!apiError} />
    </>
  )
}

export default ReadBookLinkButton
