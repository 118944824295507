import { useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { saveCardApi } from '@!/Api/payment'

export const usePaymentSaveCard = () => {
  const [apiError, setApiError] = useState({ error: null, errorInfo: null })

  const saveCardMutation = useMutation({
    mutationFn: ({ token, isUpdate }) => saveCardApi({ token, isUpdate }),
    onError: (error) => {
      if (error.data?.error) {
        const errorInfo = error.data?.ErrInfo ? error.data.ErrInfo : null
        setApiError({ error: error.data.error, errorInfo: errorInfo })
      } else {
        setApiError({ error: 'エラーが発生しました。申し訳ありませんが、ページの再読み込みまたはしばらく時間をおいてからお試しください' })
      }
    },
  })

  return [saveCardMutation, apiError]
}
