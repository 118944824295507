import { PropTypes } from 'prop-types'

import { Box, Link } from '@chakra-ui/react'

import { CountBadge } from '@!/components/Badge'

import { CartIcon } from '@!/components/Icon'

export const Cart = ({ count }) => {
  const isCountVisible = count > 0

  return (
    <Link pos="relative" href="/cart" ml={1}>
      {isCountVisible && (
        <Box pos="absolute" top={-1} right={-1} zIndex="sticky">
          <CountBadge count={count} />
        </Box>
      )}
      <Box aria-label="カート">
        <CartIcon fontSize="4xl" color="label.primary" />
      </Box>
    </Link>
  )
}

Cart.propType = {
  count: PropTypes.number.isRequired,
}
