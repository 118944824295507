import { useForm, Controller } from 'react-hook-form'
import { VStack, Box, FormControl, FormLabel, Input, Select, Text, HStack, Icon, useDisclosure } from '@chakra-ui/react'
import { QuestionIcon } from '@chakra-ui/icons'
import { SecurityCodePopupModal } from '@!/components/CreditCard/components'

export const CreditCardForm = ({ onSubmit, fontSize = 'md', children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    defaultValues: {
      cardNumber: '',
      cardName: '',
      expireMonth: '',
      expireYear: '',
      securityCode: '',
    },
  })

  const getMonthList = () => {
    return Array.from({ length: 12 }, (_, i) => {
      const month = (i + 1).toString().padStart(2, '0')
      return { value: month, name: `${month}月` }
    })
  }

  const getYearList = () => {
    const currentYear = new Date().getFullYear()
    return Array.from({ length: 10 }, (_, i) => currentYear + i)
  }

  const onSubmitForm = (data) => {
    onSubmit(data, setError)
  }

  return (
    <Box w="100%">
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <VStack>
          <Text fontSize="sm" color="red.500" w="100%">
            {errors.root?.message}
          </Text>
          <Controller
            name="cardNumber"
            control={control}
            rules={{
              required: 'カード番号を入力してください。',
              pattern: {
                value: /^\d{14,16}$/,
                message: 'カード番号は14桁から16桁の数字で入力してください。',
              },
            }}
            render={({ field }) => (
              <FormControl isInvalid={errors.cardNumber} isRequired mb="2">
                <FormLabel fontSize={fontSize}>クレジットカード番号</FormLabel>
                <Input {...field} type="tel" placeholder="0123456789012345" maxLength={16} fontSize={fontSize} bgColor="bg.secondary" />
                <Text fontSize="sm" color="red.500">
                  {errors.cardNumber?.message}
                </Text>
              </FormControl>
            )}
          />

          <Controller
            name="cardName"
            control={control}
            rules={{ required: 'カード名義を入力してください。', pattern: { value: /^[A-Z\s]+$/, message: '半角大文字のアルファベットで入力してください。' } }}
            render={({ field }) => (
              <FormControl isInvalid={errors.cardName} isRequired mb="2">
                <FormLabel fontSize={fontSize}>カード名義</FormLabel>
                <Input {...field} placeholder="TARO MANGA" fontSize={fontSize} bgColor="bg.secondary" />
                <Text fontSize="sm" color="red.500">
                  {errors.cardName?.message}
                </Text>
              </FormControl>
            )}
          />

          <FormControl isInvalid={errors.expireMonth || errors.expireYear} isRequired mb="2">
            <FormLabel fontSize={fontSize}>有効期限</FormLabel>
            <HStack w="60%">
              <Controller
                name="expireMonth"
                control={control}
                rules={{ required: '有効期限(月)を選択してください。' }}
                render={({ field }) => (
                  <Select {...field} placeholder="月" fontSize={fontSize} bgColor="bg.secondary">
                    {getMonthList().map((month) => (
                      <option key={month.value} value={month.value}>
                        {month.name}
                      </option>
                    ))}
                  </Select>
                )}
              />
              <Controller
                name="expireYear"
                control={control}
                rules={{ required: '有効期限(年)を選択してください。' }}
                render={({ field }) => (
                  <Select {...field} placeholder="年" fontSize={fontSize} bgColor="bg.secondary">
                    {getYearList().map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </Select>
                )}
              />
            </HStack>
            <Text fontSize="sm" color="red.500">
              {errors.expireMonth?.message || errors.expireYear?.message}
            </Text>
          </FormControl>

          <Controller
            name="securityCode"
            control={control}
            rules={{
              required: 'セキュリティコードを入力してください。',
              pattern: { value: /^\d{3,4}$/, message: 'セキュリティコードは3桁または4桁の数字で入力してください。' },
            }}
            render={({ field }) => (
              <FormControl isInvalid={errors.securityCode} isRequired mb="2">
                <FormLabel fontSize={fontSize}>セキュリティコード</FormLabel>
                <HStack w="60%">
                  <Input {...field} type="tel" maxLength={4} fontSize={fontSize} bgColor="bg.secondary" />
                  <Icon as={QuestionIcon} onClick={onOpen} aria-label="セキュリティコードについて" color="label.tertiary" />
                </HStack>
                <Text fontSize="sm" color="red.500">
                  {errors.securityCode?.message}
                </Text>
              </FormControl>
            )}
          />

          {/* ボタン */}
          {children}
        </VStack>
      </form>
      <SecurityCodePopupModal isOpen={isOpen} onClose={onClose} />
    </Box>
  )
}

export default CreditCardForm
