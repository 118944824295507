import { useEffect } from 'react'
import { useAtom } from 'jotai'
import { useCoinCounts, usePointCount, useAppCookies } from '@!/Hooks'
import { userHashAtom, userCoinsAtom, userPointsAtom, userUsableCoinsAtom } from '@!/atoms/userInfoAtom'
import { BookshelfIcon, BookPurchaseHistoryIcon, CoinHistoryIcon, AccountIcon, CouponIcon, AnnouncementIcon } from '@!/components/Icon'

import { Stack, Spacer, Box, SimpleGrid, LinkBox, LinkOverlay, Text, Flex } from '@chakra-ui/react'

import { PointBox } from './PointBox'
import { CoinBox } from './CoinBox'
import { MenuLink } from './MenuLink'
import { MenuFooter } from './MenuFooter'
import { SafeSearch } from './SafeSearch'

export const LoggedInMenuBody = () => {
  const [userHash] = useAtom(userHashAtom)
  const [, setUserCoins] = useAtom(userCoinsAtom)
  const [userUsableCoins] = useAtom(userUsableCoinsAtom)
  const [userPoints, setUserPoints] = useAtom(userPointsAtom)
  const { removeCoinCountsCookie, removePointCountsCookie } = useAppCookies()
  const { data: coins, refetch: refetchCoins } = useCoinCounts(userHash)
  const { data: points, refetch: refetchPoints } = usePointCount(userHash)

  useEffect(() => {
    ;(async () => {
      await removeCoinCountsCookie()
      await removePointCountsCookie()
      refetchCoins()
      refetchPoints()
    })()
  }, [])

  useEffect(() => {
    setUserCoins(coins)
  }, [coins])

  useEffect(() => {
    setUserPoints(points)
  }, [points])

  const listLinks = [
    { text: '本棚', url: '/user/bookshelf', icon: BookshelfIcon, iconW: '30px', iconH: '38px' },
    { text: '作品購入履歴', url: '/user/book_purchase_histories', icon: BookPurchaseHistoryIcon, iconW: '28px', iconH: '36px' },
    { text: 'コイン購入履歴', url: '/user/coin_histories', icon: CoinHistoryIcon, iconW: '32px', iconH: '40px' },
    { text: 'アカウント情報', url: '/user/account', icon: AccountIcon, iconW: '33px', iconH: '34px' },
    { text: 'クーポン', url: '/user/coupons', icon: CouponIcon, iconW: '37px', iconH: '29px' },
    { text: 'お知らせ', url: '/announcements', icon: AnnouncementIcon, iconW: '31px', iconH: '34px' },
  ]

  const footerLinks = [
    { text: '問い合わせ', url: '/inquiry' },
    { text: 'ヘルプ', url: '/static_page/help' },
  ]

  return (
    <Stack>
      <Stack p={'24px'} spacing={0}>
        <Box borderBottom="solid 1px #E0E0E0">
          <PointBox pointCount={userPoints} />
        </Box>
        <Box borderBottom="solid 1px #E0E0E0">
          <CoinBox coinCount={userUsableCoins} />
        </Box>
        <Box borderBottom="solid 1px #E0E0E0" py={4}>
          <SafeSearch />
        </Box>
        <SimpleGrid columns={3} spacing={0} py={5}>
          {listLinks.map((link, index) => {
            const Icon = link.icon
            return (
              <Flex key={`link-${index}`} justifyContent="center" alignItems="center">
                <LinkBox my={5} textAlign="center">
                  <LinkOverlay href={link.url}>
                    {Icon && <Icon w={link.iconW} h={link.iconH} />}
                    <Text fontSize="3xs" mt={2}>
                      {link.text}
                    </Text>
                  </LinkOverlay>
                </LinkBox>
              </Flex>
            )
          })}
        </SimpleGrid>
        <Spacer />
      </Stack>
      <MenuFooter>
        {footerLinks.map((link, index) => (
          <MenuLink key={`footer-${index}`} text={link.text} url={link.url} p={'12px 0'} fontSize={'.6825em'} textAlign={'center'} />
        ))}
      </MenuFooter>
    </Stack>
  )
}
