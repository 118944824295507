import { useState } from 'react'
import { PrimaryButton } from '@!/components/Button'
import { buildUrlString } from '@!/Util'
import { useAtom } from 'jotai'
import { userHashAtom } from '@!/atoms/userInfoAtom'
import { useNavigationHandler } from '@!/Hooks/'
import { YesNoPopupModal } from '@!/components/Modal'

export const BuyNowButton = ({ volume, children, ...props }) => {
  const [userHash] = useAtom(userHashAtom)
  const [warningModalOpen, setWarningModalOpen] = useState(false)
  const { redirectToLoginWithToast } = useNavigationHandler()

  const handleClick = () => {
    if (userHash === undefined) {
      redirectToLoginWithToast()
    } else {
      if (volume.rating > 2) {
        setWarningModalOpen(true)
      } else {
        executeAction()
      }
    }
  }

  const executeAction = () => {
    window.location.href = buildUrlString('/store/purchase', { book_id: volume.distribution_id })
  }

  const handleCancelDialog = () => {
    setWarningModalOpen(false)
  }

  const handleWarningSubmit = () => {
    setWarningModalOpen(false)
    executeAction()
  }

  return (
    <>
      <PrimaryButton width="100%" height="100%" as="a" {...props} onClick={handleClick}>
        {children}
      </PrimaryButton>
      <YesNoPopupModal
        title="この作品には性的・暴力的な表現が一部含まれている可能性があります。ご了承の上購入しますか?"
        handleCancel={() => handleCancelDialog()}
        handleSubmit={() => handleWarningSubmit()}
        cancelTitle="キャンセル"
        submitTitle="はい"
        isOpen={warningModalOpen}
      />
    </>
  )
}
