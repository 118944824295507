import { useEffect, useState, useMemo } from 'react'
import { PropTypes } from 'prop-types'

import { CircularProgressModal, ConfirmPopupModal } from '@!/components/Modal'
import { PrimaryButton } from '@!/components/Button'
import { getEndDate } from '@!/User/Bookshelf/util/Check'
import { storeBookColophonUrl } from '@!/Util/location'
import { useReadBook } from '@!/Hooks'

export const ReadBookButton = ({ volume }) => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)
  const [popupText, setPopupText] = useState('')

  const endDate = useMemo(() => getEndDate(volume), [volume])

  const [readPurchasedBookMutation, redirectUrl, apiError, setApiError] = useReadBook('purchased')

  useEffect(() => {
    if (!redirectUrl) return

    window.location.href = redirectUrl
  }, [redirectUrl])

  const execReadBook = async () => {
    setConfirmModalOpen(false)

    const colophonUrl = storeBookColophonUrl(volume.distribution_id, false)
    readPurchasedBookMutation.mutate({ bookId: volume.distribution_id, colophonUrl: colophonUrl })
  }

  const handleReadBook = (event) => {
    event.preventDefault()

    if (endDate) {
      const title = `「${volume.title_name}」は${endDate}まで閲覧できます。`
      setPopupText(title)
      setConfirmModalOpen(true)
    } else {
      execReadBook()
    }
  }

  const handleApiError = () => {
    console.log(apiError)
    setApiError(null)
  }

  return (
    <>
      <PrimaryButton width="100%" height="37px" onClick={(event) => handleReadBook(event)}>
        作品を読む
      </PrimaryButton>
      <ConfirmPopupModal handlePopup={() => execReadBook()} text={popupText} isOpen={confirmModalOpen} />
      <ConfirmPopupModal text="エラーが発生しました。" handlePopup={() => handleApiError()} isOpen={!!apiError} />
      <CircularProgressModal isOpen={readPurchasedBookMutation.isPending} />
    </>
  )
}

ReadBookButton.propType = {
  volume: PropTypes.object.isRequired,
}
