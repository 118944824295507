import { useForm } from 'react-hook-form'
import { Box, FormControl, FormLabel, FormErrorMessage, Input, Textarea, Text, Link, VStack } from '@chakra-ui/react'
import { PrimaryButton } from '@!/components/Button'

const InquiryForm = ({ onSubmit, isSubmitting }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  return (
    <Box maxWidth="600px" m="auto" py="10" px="4">
      <Text fontSize="sm" mb="4">
        よくある質問は
        <Link href="/static_page/help">こちら</Link>
        をご確認ください。
      </Text>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack spacing="4" align="stretch" fontSize="sm">
          <FormControl isInvalid={errors.name} isRequired>
            <FormLabel htmlFor="name" fontSize="sm">
              お名前
            </FormLabel>
            <Input id="name" {...register('name', { required: 'お名前は必須です' })} />
            <FormErrorMessage>{errors.name && errors.name.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={errors.email} isRequired>
            <FormLabel htmlFor="email" fontSize="sm">
              メールアドレス
            </FormLabel>
            <Input
              id="email"
              type="email"
              placeholder="email@example.com"
              {...register('email', {
                required: 'メールアドレスは必須です',
                pattern: {
                  value: /^[\w+\-.]+@[a-z\d\-.]+\.[a-z]+$/i,
                  message: '有効なメールアドレスを入力してください',
                },
              })}
            />
            <FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
          </FormControl>

          <FormControl>
            <FormLabel htmlFor="title" fontSize="sm">
              タイトル
            </FormLabel>
            <Input id="title" {...register('title')} />
          </FormControl>

          <FormControl isInvalid={errors.description} isRequired>
            <FormLabel htmlFor="description" fontSize="sm">
              お問い合わせ内容 (1000文字以下)
            </FormLabel>
            <Textarea
              id="description"
              {...register('description', {
                required: 'お問い合わせ内容は必須です',
                maxLength: { value: 1000, message: 'お問い合わせ内容は1000文字以下にしてください' },
              })}
            />
            <FormErrorMessage>{errors.description && errors.description.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={errors.screenDetail}>
            <FormLabel htmlFor="screenDetail" fontSize="sm">
              問題が発生した画面 (1000文字以下)
            </FormLabel>
            <Textarea
              id="screenDetail"
              {...register('screenDetail', {
                maxLength: { value: 1000, message: '問題が発生した画面については1000文字以下にしてください' },
              })}
            />
            <FormErrorMessage>{errors.screenDetail && errors.screenDetail.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={errors.operationDetail}>
            <FormLabel htmlFor="operationDetail" fontSize="sm">
              行なった操作 (1000文字以下)
            </FormLabel>
            <Textarea
              id="operationDetail"
              {...register('operationDetail', {
                maxLength: { value: 1000, message: '行なった操作については1000文字以下にしてください' },
              })}
            />
            <FormErrorMessage>{errors.operationDetail && errors.operationDetail.message}</FormErrorMessage>
          </FormControl>
          <PrimaryButton type="submit" isDisabled={isSubmitting}>
            送信
          </PrimaryButton>
        </VStack>
      </form>
    </Box>
  )
}

export default InquiryForm
