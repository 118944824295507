import { useEffect, useMemo } from 'react'
import { useAtom } from 'jotai'
import { Box, Input, Text } from '@chakra-ui/react'
import { accessIdAtom, accessTokenAtom, payStartUrlAtom, payTypeAtom } from '@!/atoms/paymentAtom'

const PaymentRedirect = () => {
  const [accessId] = useAtom(accessIdAtom)
  const [accessToken] = useAtom(accessTokenAtom)
  const [payStartUrl] = useAtom(payStartUrlAtom)
  const [payType] = useAtom(payTypeAtom)

  const text = useMemo(() => {
    if (payType === 'au') {
      return 'auかんたん決済画面へ遷移します。'
    } else if (payType === 'softbank') {
      return 'ソフトバンク決済画面へ遷移します。'
    } else if (payType === 'paypay') {
      return 'PayPay決済画面へ遷移します。'
    } else if (payType === 'docomo') {
      return 'ドコモ決済画面へ遷移します。'
    }
    return 'コイン購入画面へ戻ります。'
  }, [payType])

  useEffect(() => {
    if (['docomo', 'softbank', 'au', 'paypay'].includes(payType)) return

    window.location.href = '/user/payment'
  }, [payType])

  useEffect(() => {
    if (accessId && accessToken && payStartUrl) {
      document.startCall.submit()
    }
  }, [accessId, accessToken, payStartUrl])

  return (
    <Box py="8" px="4">
      <form name="startCall" action={payStartUrl} method="POST">
        <Box>
          <Text>{text}</Text>
          <Input type="hidden" name="AccessID" value={accessId} />
          <Input type="hidden" name="Token" value={accessToken} />
        </Box>
      </form>
    </Box>
  )
}

export default PaymentRedirect
