import { LinkBox, LinkOverlay, HStack, Box, Text, Stack, Grid, GridItem } from '@chakra-ui/react'
import { buildUrlString } from '@!/Util'
import { CoverImage, PointBackCountLabel, PriceLabel } from '@!/Store/Common/components'
import { PrimaryActionButton, SampleButton } from '@!/Store/Common/components/BookList/Button'
import { CartIconButton } from '@!/Store/Common/components/Cart'

export const BookListItem = ({ volume, rating }) => {
  // canAccess: 配信が終了していない、または購入済みの場合に true
  // 作品詳細画面への遷移や、読める(配信中の場合は買う)ボタン表示の判定に使用
  const canAccess = !volume.ended || volume.purchased

  // canBuy: 配信が終了しておらず、未購入の場合にtrue
  // カートに追加できるかや、値段表示の判定に使用
  const canBuy = !volume.ended && !volume.purchased

  // isExpired: 配信が終了しており、未購入の場合にtrue
  // 「配信終了」メッセージの表示に使用
  const isExpired = volume.ended && !volume.purchased

  return (
    <LinkBox w="100%">
      <Grid templateColumns="auto 1fr">
        <GridItem colSpan={1}>
          <Box mr={4}>
            <LinkOverlay href={canAccess ? buildUrlString(`/store/books/${volume.distribution_id}`) : undefined}>
              <CoverImage src={volume.image_url} alt={volume.full_name} objectFit="contain" width="70px" />
            </LinkOverlay>
          </Box>
        </GridItem>
        <GridItem colSpan={1}>
          <Text fontWeight="bold" size="sm">
            {volume.full_name}
          </Text>
          {isExpired && (
            <Text fontSize="sm" color="label.accent" mt="2">
              配信終了
            </Text>
          )}
          {canBuy && (
            <HStack spacing={0} justifyContent="space-between" mt={2} mb={3}>
              <Stack spacing={0}>
                <PriceLabel price={volume.price} properPrice={volume.proper_price} />
                <PointBackCountLabel pointBackCount={volume.point_back_count} />
              </Stack>
              <CartIconButton detail={volume} />
            </HStack>
          )}
        </GridItem>
        <GridItem colSpan={1}></GridItem>
        <GridItem colSpan={1}>
          {canAccess && (
            <HStack spacing={0}>
              <Box w="50%" mr="9px">
                <SampleButton
                  distributionId={volume.distribution_id}
                  sampleBookId={volume.sample_book_id}
                  rating={rating}
                  price={volume.price}
                  isPurchased={volume.purchased}
                />
              </Box>
              <Box w="50%" h="37px">
                <PrimaryActionButton volume={{ ...volume, rating }} />
              </Box>
            </HStack>
          )}
        </GridItem>
      </Grid>
    </LinkBox>
  )
}
