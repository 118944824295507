import { useAtom } from 'jotai'
import { Box, Button } from '@chakra-ui/react'
import { cardInfoAtom } from '@!/atoms/paymentAtom'
import { savePreviousPaymentMethod } from '@!/Util/localStorageData'
import { CircularProgressModal } from '@!/components/Modal'
import { CreditCardForm } from '@!/components/CreditCard/components'
import { useGmoPayment } from '@!/components/CreditCard/hooks/useGmoPayment'
import { usePaymentSaveCard } from '@!/components/CreditCard/hooks/usePaymentSaveCard'

const CreditCardFormContainer = ({ setShowNewCardForm }) => {
  const [, setCardInfo] = useAtom(cardInfoAtom)
  const [saveCardMutation, getErrorMessage] = usePaymentSaveCard()
  const { handleGetGmoToken } = useGmoPayment()

  const onSubmit = async (data, setError) => {
    const result = await handleGetGmoToken(data, setError)
    if (result.success) {
      saveCardMutation.mutate(
        { token: result.token, isUpdate: result.isUpdate },
        {
          onSuccess: () => {
            savePreviousPaymentMethod('credit')
            setShowNewCardForm(false)
            setCardInfo({
              number: data.cardNumber,
              name: data.cardName,
              month: data.expireMonth,
              year: data.expireYear,
              securityCode: data.securityCode,
            })
          },
          onError: (error) => {
            setError('root', { type: 'manual', message: getErrorMessage(error) })
          },
        },
      )
    }
  }

  return (
    <>
      <CircularProgressModal isOpen={saveCardMutation.isPending} />
      <Box mt="4">
        <CreditCardForm onSubmit={onSubmit} fontSize="xs" mt="5">
          <Button type="submit" bgColor="bg.blueGray" color="white" fontSize="sm" w="155px" h="36px" my="2">
            登録する
          </Button>
        </CreditCardForm>
      </Box>
    </>
  )
}

export default CreditCardFormContainer
