import { useQuery } from '@tanstack/react-query'
import { coinCountsApi } from '@!/Api/user'
import { userCoinKeys } from '@!/Api/queryKeys'
import { useAppCookies } from '@!/Hooks'

export const useCoinCounts = (userHash) => {
  const { coinCountsCookie } = useAppCookies()
  return useQuery({
    queryKey: userCoinKeys.coinCounts(userHash),
    queryFn: async () => {
      if (userHash) {
        // cookieから取得できた場合はその値を返す
        if (coinCountsCookie) return coinCountsCookie

        // cookieから取得できなかった場合はAPIを実行する
        try {
          return await coinCountsApi()
        } catch (error) {
          console.log(error)
        }
      }

      return {}
    },
  })
}
