import { useEffect } from 'react'
import { useAtom } from 'jotai'
import { breadcrumbAtom } from '@!/atoms/breadcrumbAtom'
import { Heading, Link, Box, Text } from '@chakra-ui/react'

export const PrivacyPolicy = () => {
  const [, setBreadcrumb] = useAtom(breadcrumbAtom)

  useEffect(() => {
    setBreadcrumb([{ name: 'マンガBANGブックス', ref: '/' }, { name: 'プライバシーポリシー' }])
  }, [])

  return (
    <Box p="4">
      <Heading fontSize="xl" as="h1" mb={3}>
        「マンガBANG（アプリ）」「マンガBANGブックス」プライバシーポリシー
      </Heading>
      <Text>当社は、下記のポリシーに基づき利用者の個人情報保護に最大限の注意を払って本サービスを運営しております。</Text>
      <Heading as="h3" size="md" mt={3} mb={3}>
        1. 個人情報の取得について
      </Heading>
      <Text>
        個人情報とは、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日その他の記述等により特定の個人を識別することができるもの（他の情報と容易に照合することができ、それにより特定の個人を識別することができることとなるものを含みます。）及び個人識別符号が含まれるものを指します。
      </Text>
      <Heading as="h3" size="md" mt={3} mb={3}>
        2. 個人情報の利用目的について
      </Heading>
      <Text>
        当社は、利用者の個人情報を以下の目的で利用することができるものとします。
        <br />
        <br />
        ・本サービスの提供のため
        <br />
        ・本サービス及び本サービスに関連する情報の提供のため
        <br />
        ・利用者の本人確認、クレジットカードの認証サービスのため
        <br />
        ・アフターサービス、各種問い合わせ対応のため
        <br />
        ・お客様の年齢、職業、性別、趣味・嗜好、利用状況等に合わせて本サービスのページ上の情報、本サービス及び広告配信をカスタマイズするため
        <br />
        ※取得した閲覧履歴や購買履歴等の情報を分析して、趣味・嗜好に応じたコンテンツその他本サービスに関するレコメンド提供及び広告配信のために利用いたします。
        <br />
        ・本サービスの改善や新サービスの開発等に役立てるため <br />
        ・本サービスの料金に関する連絡のため
        <br />
        ・その他、前各号に付随する目的のため <br />
      </Text>
      <Heading as="h3" size="md" mt={3} mb={3}>
        3. 個人情報の利用制限について
      </Heading>
      <Text>
        当社は、以下に定める場合を除いて、事前に利用者本人の同意を得ず、利用目的の達成に必要な範囲を超えて個人情報を利用することはありません。
        <br />
        <br />
        (1) 法令に基づく場合
        <br />
        (2) 人の生命、身体又は財産の保護のために必要がある場合であって、本人の同意を得ることが困難である場合
        <br />
        (3) 公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難である場合
        <br />
        (4)
        国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがある場合
      </Text>

      <Heading as="h3" size="md" mt={3} mb={3}>
        4. 個人情報の第三者提供について
      </Heading>
      <Text>
        当社は、以下に定める場合を除いて、事前に利用者本人の同意を得ず、個人データを第三者に提供することはありません。
        <br />
        <br />
        (1) 裁判所、検察庁、警察、税務署、弁護士会又はこれらに準じた権限を持つ機関から、個人情報の開示を求められた場合など、法令に基づく場合
        <br />
        (2) 人の生命、身体又は財産の保護のために必要がある場合であって、本人の同意を得ることが困難である場合
        <br />
        (3) 公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難である場合
        <br />
        (4)
        国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがある場合
        <br />
        <br />
        ただし、次に掲げる場合において、当該個人データの提供を受ける者は、前各項の規定の適用については、第三者に該当しないものとします。
        <br />
        <br />
        (1) 個人情報取扱事業者が利用目的の達成に必要な範囲内において個人データの取扱いの全部又は一部を委託することに伴って当該個人データが提供される場合
        <br />
        (2) 合併その他の事由による事業の承継に伴って個人データが提供される場合
        <br />
        (3)
        特定の者との間で共同して利用される個人データが当該特定の者に提供される場合であって、その旨並びに共同して利用される個人データの項目、共同して利用する者の範囲、利用する者の利用目的及び当該個人データの管理について責任を有する者の氏名又は名称について、あらかじめ、本人に通知し、又は本人が容易に知り得る状態に置いている場合
        <br />
      </Text>

      <Heading as="h3" size="md" mt={3} mb={3}>
        5. 個人情報の安全管理について
      </Heading>
      <Text>
        当社では、個人情報の漏洩または毀損の防止ならびにその他個人情報の安全管理が行われるよう、個人情報の適切な管理を指導しています。また、個人情報の取扱いを外部に委託する場合には、機密保持契約を締結のうえ委託先を監督します。
      </Text>

      <Heading as="h3" size="md" mt={3} mb={3}>
        6. 個人情報の開示等
      </Heading>
      <Text>
        (1)
        当社は、利用者から、個人情報保護法の定めに基づき当該利用者が識別される個人情報の利用目的の通知及び個人情報の開示（以下｢開示等｣といいます。）を求められたときは、利用者ご本人からのご請求であることを確認の上で、利用者に対し、遅滞なく開示等を行います。当該個人情報等が存在しない場合にはその旨を通知します。ただし、以下の場合は、その全部又は一部を開示等しない場合があります。{' '}
        <br />
        ① お客様ご本人又は第三者の生命、身体、財産その他の権利利益を害するおそれがある場合 <br />
        ② 当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合 <br />
        ③ 他の法令に違反するおそれがある場合
        <br />
        (2) 開示等の請求につきましては、第9項記載のメールアドレスまでご連絡ください。
        <br />
        なお、開示請求と利用目的の通知につきましては、当社は、実費を勘案した合理的な範囲内の手数料を徴収することができるものとします。
      </Text>

      <Heading as="h3" size="md" mt={3} mb={3}>
        7. 個人情報の訂正・利用停止等
      </Heading>
      <Text>
        (1)
        当社は、利用者の個人情報等について、個人情報保護法の定めに基づきその内容の訂正、追加若しくは削除又は利用の停止若しくは消去を求められた場合には、利用者ご本人からのご請求であることを確認したうえで、遅滞なく必要な調査を行い、その結果に基づき合理的な期間に対応します。
        <br />
        (2) 利用者が個人情報等の削除を希望する場合は、利用規約に規定するほか、iPhone端末およびiPad端末（iOS端末）の利用者は、本サービスの[マイページ] &gt;
        [会員情報] メニュー &gt; [アカウント削除]
        から、いつでもアカウント削除を要求することができます。その他の請求については、10.記載のお問い合わせ先にご連絡下さい。なお、利用者からの要求を受けた場合、当社は合理的速やかに利用者のアカウントを無効化し、適用法令に従って個人情報等を消去します。
      </Text>

      <Heading as="h3" size="md" mt={3} mb={3}>
        8. クッキーについて
      </Heading>
      <Text>
        本サービスでは、クッキー(Cookie)と呼ばれる技術を利用しています。クッキーとは、特定のテキスト情報をお客様の利用する通信機器に一時的にデータとして保持させ、接続の度にそのデータを基にお客様を識別させる仕組みをいいます。なお、クッキー単体では特定の個人の識別は困難ですが、たとえば、クッキー情報と当社が保有する他の個人情報との組み合わせにより特定の個人を識別できる場合には、全体として、個人情報として扱われることになります。
        <br />
        当社では、クッキーの利用を前提としたサービスを提供しているため、クッキーの利用を許可しない場合、当社の一部のサービスを受けられないことがあります。クッキーの利用を許可するかどうかは、お客様のブラウザで設定できます。必要に応じて設定を確認してください。
        <br />
        また本サービス上で、広告表示回数を計測する等のため、当社以外の第三者企業の広告システムによりクッキーが送信されることがあります。
      </Text>

      <Heading as="h3" size="md" mt={3} mb={3}>
        9. プライバシーポリシーの変更について
      </Heading>
      <Text>
        当社は、本プライバシーポリシーを変更する場合があります。
        <br />
        本ページを都度ご確認の上、本サービスをご利用の際には本プライバシーポリシーをご理解いただくようお願いします。
      </Text>

      <Heading as="h3" size="md" mt={3} mb={3}>
        10. お問い合わせ先
      </Heading>
      <Text>
        個人情報の取扱いに関する苦情やお問い合わせは、下記の窓口までお願いします。
        <br />
        <Link href="https://amazia.co.jp/about-us/">株式会社Amazia</Link>
        <br />
        サポートデスク
        <br />
        info@support-mangabang.jp
      </Text>

      <Heading as="h3" size="md" mt={3} mb={3}>
        利用者情報の外部送信について
      </Heading>
      <Text>
        当社では、本サービスの改善や最適な広告配信のために利用者情報を収集し、外部事業者に送信することがあります。本サービスで外部事業者に送信される利用者情報や送信先等は以下のとおりです。
      </Text>

      <Text>
        Firebase
        <br />
        ・本サービス：マンガBANG（アプリ）
        <br />
        ・当社の利用目的：サービス改善のためのデータ収集及び分析
        <br />
        ・収集する利用者情報：広告ID（IDFA/ADID)
        <br />
        ・送信先・ポリシー等：Google LLC
        <br />
        <Link fontSize="sm" isExternal href="https://policies.google.com/privacy?hl=ja">
          https://policies.google.com/privacy?hl=ja
        </Link>
        <br />
        ・送信先の利用目的：サービス改善のためのデータ収集及び分析
        <br />
      </Text>

      <Text>
        Google Analytics
        <br />
        ・本サービス：マンガBANG（WEB）
        <br />
        ・当社の利用目的：サービス改善のためのデータ収集及び分析
        <br />
        ・収集する利用者情報：Cookie
        <br />
        ・送信先・ポリシー等：Google LLC
        <br />
        <Link fontSize="sm" isExternal href="https://policies.google.com/privacy?hl=ja">
          https://policies.google.com/privacy?hl=ja
        </Link>
        <br />
        ・送信先の利用目的：サービス改善のためのデータ収集及び分析
        <br />
      </Text>

      <Text>
        appsflyer
        <br />
        ・本サービス：マンガBANG（アプリ）
        <br />
        ・当社の利用目的：マーケティング キャンペーンの効果を測定および分析
        <br />
        ・収集する利用者情報：広告ID（IDFA/ADID）
        <br />
        ・送信先・ポリシー等：AppsFlyer Ltd.
        <br />
        <Link fontSize="sm" isExternal href="https://www.appsflyer.com/legal/services-privacy-policy/">
          https://www.appsflyer.com/legal/services-privacy-policy/
        </Link>
        <br />
        ・送信先の利用目的：広告効果を測定するため
        <br />
      </Text>

      <Text>
        Adstir
        <br />
        ・本サービス：マンガBANG（アプリ）
        <br />
        ・当社の利用目的：利用者に適した当社および他社の広告の表示・配信のため
        <br />
        ・収集する利用者情報：広告ID（IDFA/ADID）
        <br />
        ・送信先・ポリシー等：UNITED Marketing Technologies, Inc.
        <br />
        <Link fontSize="sm" isExternal href="https://mt.united.jp/rule.html">
          https://mt.united.jp/rule.html
        </Link>
        <br />
        ・送信先の利用目的：最適な広告配信及び広告掲載効果測定のため
        <br />
      </Text>

      <Text>
        Admob
        <br />
        ・本サービス：マンガBANG（アプリ）
        <br />
        ・当社の利用目的：利用者に適した当社および他社の広告の表示・配信のため
        <br />
        ・収集する利用者情報：広告ID（IDFA/ADID）
        <br />
        ・送信先・ポリシー等：Google Asia Pacific Pte. Ltd
        <br />
        <Link fontSize="sm" isExternal href="https://policies.google.com/privacy?hl=ja">
          https://policies.google.com/privacy?hl=ja
        </Link>
        <br />
        ・送信先の利用目的：最適な広告配信及び広告掲載効果測定のため
        <br />
      </Text>

      <Text>
        Facebook Audience Network
        <br />
        ・本サービス：マンガBANG（アプリ）
        <br />
        ・当社の利用目的：利用者に適した当社および他社の広告の表示・配信のため
        <br />
        ・収集する利用者情報：広告ID（IDFA/ADID）
        <br />
        ・送信先・ポリシー等：Meta Platforms, Inc
        <br />
        <Link fontSize="sm" isExternal href="https://www.facebook.com/privacy/policy/?entry_point=help_trayy">
          https://www.facebook.com/privacy/policy/?entry_point=help_trayy
        </Link>
        <br />
        ・送信先の利用目的：最適な広告配信及び広告掲載効果測定のため
        <br />
      </Text>

      <Text>
        FIVE
        <br />
        ・本サービス：マンガBANG（アプリ）
        <br />
        ・当社の利用目的：利用者に適した当社および他社の広告の表示・配信のため
        <br />
        ・収集する利用者情報：広告ID（IDFA/ADID）
        <br />
        ・送信先・ポリシー等：LINEヤフー株式会社
        <br />
        <Link fontSize="sm" isExternal href="https://line.me/ja/terms/policy/">
          https://line.me/ja/terms/policy/
        </Link>
        <br />
        ・送信先の利用目的：最適な広告配信及び広告掲載効果測定のため
        <br />
      </Text>

      <Text>
        Applovin
        <br />
        ・本サービス：マンガBANG（アプリ）
        <br />
        ・当社の利用目的：利用者に適した当社および他社の広告の表示・配信のため
        <br />
        ・収集する利用者情報：広告ID（IDFA/ADID）
        <br />
        ・送信先・ポリシー等：AppLovin Corporation
        <br />
        <Link fontSize="sm" isExternal href="https://www.applovin.com/privacy/">
          https://www.applovin.com/privacy/
        </Link>
        <br />
        ・送信先の利用目的：最適な広告配信及び広告掲載効果測定のため
        <br />
      </Text>

      <Text>
        Pangle
        <br />
        ・本サービス：マンガBANG（アプリ）
        <br />
        ・当社の利用目的：利用者に適した当社および他社の広告の表示・配信のため
        <br />
        ・収集する利用者情報：広告ID（IDFA/ADID）
        <br />
        ・送信先・ポリシー等：Bytedance Pte. Ltd
        <br />
        <Link fontSize="sm" isExternal href="https://www.pangleglobal.com/jp/privacy">
          https://www.pangleglobal.com/jp/privacy
        </Link>
        <br />
        ・送信先の利用目的：最適な広告配信及び広告掲載効果測定のため
        <br />
      </Text>

      <Text>
        nend
        <br />
        ・本サービス：マンガBANG（アプリ）
        <br />
        ・当社の利用目的：利用者に適した当社および他社の広告の表示・配信のため
        <br />
        ・収集する利用者情報：広告ID（IDFA/ADID）
        <br />
        ・送信先・ポリシー等：株式会社ファンコミュニケーションズ
        <br />
        <Link fontSize="sm" isExternal href="https://www.fancs.com/privacypolicy">
          https://www.fancs.com/privacypolicy
        </Link>
        <br />
        ・送信先の利用目的：最適な広告配信及び広告掲載効果測定のため
        <br />
      </Text>

      <Text>
        maio
        <br />
        ・本サービス：マンガBANG（アプリ）
        <br />
        ・当社の利用目的：利用者に適した当社および他社の広告の表示・配信のため
        <br />
        ・収集する利用者情報：広告ID（IDFA/ADID）
        <br />
        ・送信先・ポリシー等：株式会社アイモバイル
        <br />
        <Link fontSize="sm" isExternal href="https://www.i-mobile.co.jp/privacy.html">
          https://www.i-mobile.co.jp/privacy.html
        </Link>
        <br />
        ・送信先の利用目的：最適な広告配信及び広告掲載効果測定のため
        <br />
      </Text>

      <Text>
        ironsource
        <br />
        ・本サービス：マンガBANG（アプリ）
        <br />
        ・当社の利用目的：利用者に適した当社および他社の広告の表示・配信のため
        <br />
        ・収集する利用者情報：広告ID（IDFA/ADID）
        <br />
        ・送信先・ポリシー等：ironSource Ltd.
        <br />
        <Link fontSize="sm" isExternal href="https://www.is.com/privacy-policy/">
          https://www.is.com/privacy-policy/
        </Link>
        <br />
        ・送信先の利用目的：最適な広告配信及び広告掲載効果測定のため
        <br />
      </Text>

      <Text>
        Mintegral
        <br />
        ・本サービス：マンガBANG（アプリ）
        <br />
        ・当社の利用目的：利用者に適した当社および他社の広告の表示・配信のため
        <br />
        ・収集する利用者情報：広告ID（IDFA/ADID）
        <br />
        ・送信先・ポリシー等：Adlogic Technology Pte. Ltd.
        <br />
        <Link fontSize="sm" isExternal href="https://www.mintegral.com/en/privacy/">
          https://www.mintegral.com/en/privacy/
        </Link>
        <br />
        ・送信先の利用目的：最適な広告配信及び広告掲載効果測定のため
        <br />
      </Text>

      <Text>
        Unity
        <br />
        ・本サービス：マンガBANG（アプリ）
        <br />
        ・当社の利用目的：利用者に適した当社および他社の広告の表示・配信のため
        <br />
        ・収集する利用者情報：広告ID（IDFA/ADID）
        <br />
        ・送信先・ポリシー等：Unity Technologies
        <br />
        <Link fontSize="sm" isExternal href="https://unity.com/legal/privacy-policy">
          https://unity.com/legal/privacy-policy
        </Link>
        <br />
        ・送信先の利用目的：最適な広告配信及び広告掲載効果測定のため
        <br />
      </Text>

      <Text>
        Tapjoy
        <br />
        ・本サービス：マンガBANG（アプリ）
        <br />
        ・当社の利用目的：利用者に適した当社および他社の広告の表示・配信のため
        <br />
        ・収集する利用者情報：広告ID（IDFA/ADID）
        <br />
        ・送信先・ポリシー等：Tapjoy, Inc.
        <br />
        <Link fontSize="sm" isExternal href="https://dev.tapjoy.com/jp/legal/Privacy-Policy">
          https://dev.tapjoy.com/jp/legal/Privacy-Policy
        </Link>
        <br />
        ・送信先の利用目的：最適な広告配信及び広告掲載効果測定のため
        <br />
      </Text>

      <Text>
        Appdriver
        <br />
        ・本サービス：マンガBANG（アプリ）
        <br />
        ・当社の利用目的：アクションリワード広告の成果測定及び報酬の付与
        <br />
        ・収集する利用者情報：Cookie
        <br />
        ・送信先・ポリシー等：株式会社ADWAYS DEEE
        <br />
        <Link fontSize="sm" isExternal href="https://adways-deee.net/privacy">
          https://adways-deee.net/privacy
        </Link>
        <br />
        ・送信先の利用目的：広告主に対する広告効果測定結果の報告
        <br />
      </Text>

      <Text>
        CAWISE
        <br />
        ・本サービス：マンガBANG（アプリ）
        <br />
        ・当社の利用目的：アクションリワード広告の成果測定及び報酬の付与
        <br />
        ・収集する利用者情報：Cookie
        <br />
        ・送信先・ポリシー等：株式会社サイバーエージェント
        <br />
        <Link fontSize="sm" isExternal href="https://www.cyberagent.co.jp/privacy/">
          https://www.cyberagent.co.jp/privacy/
        </Link>
        <br />
        ・送信先の利用目的：広告主に対する広告効果測定結果の報告
        <br />
      </Text>

      <Text>
        GREE Ads
        <br />
        ・本サービス：マンガBANG（アプリ）
        <br />
        ・当社の利用目的：アクションリワード広告の成果測定及び報酬の付与
        <br />
        ・収集する利用者情報：Cookie
        <br />
        ・送信先・ポリシー等：グリー株式会社
        <br />
        <Link fontSize="sm" isExternal href="https://ads.gree.net/ja/ads-privacy/">
          https://ads.gree.net/ja/ads-privacy/
        </Link>
        <br />
        ・送信先の利用目的：広告主に対する広告効果測定結果の報告
        <br />
      </Text>

      <Text>
        ADroute
        <br />
        ・本サービス：マンガBANG（WEB）
        <br />
        ・当社の利用目的：利用者に適した当社および他社の広告の表示・配信のため
        <br />
        ・収集する利用者情報：Cookie
        <br />
        ・送信先・ポリシー等：アクセルマーク株式会社
        <br />
        <Link fontSize="sm" isExternal href="https://www.axelmark.co.jp/privacy/">
          https://www.axelmark.co.jp/privacy/
        </Link>
        <br />
        ・送信先の利用目的：最適な広告配信及び広告掲載効果測定のため
        <br />
      </Text>
    </Box>
  )
}
