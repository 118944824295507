import { useMemo } from 'react'
import { BorderedGrid } from '@!/components/Grid/BorderedGrid'

import { FooterMenuItem } from './FooterMenuItem'
import { FooterMenuBlankItem } from './FooterMenuBlankItem'
import { ContentContainer } from '@!/components/Container'
import { getDevice } from '@!/Util/device'

export const FooterMenus = () => {
  const device = useMemo(() => getDevice(), [])

  return (
    <ContentContainer my={device === 'pc' ? 5 : 0}>
      <BorderedGrid columns={2} showSideBorders={device !== 'sp'}>
        <FooterMenuItem url="/announcements">お知らせ</FooterMenuItem>
        <FooterMenuItem url="/static_page/help">ヘルプ</FooterMenuItem>
        <FooterMenuItem url="/guide">初めての方へ</FooterMenuItem>
        <FooterMenuItem url="/event">ポイント還元について</FooterMenuItem>
        <FooterMenuItem url="/inquiry">お問い合わせ</FooterMenuItem>
        <FooterMenuItem url="/static_page/terms">利用規約</FooterMenuItem>
        <FooterMenuItem url="/requirements" target="_blank">
          推奨環境
        </FooterMenuItem>
        <FooterMenuBlankItem />
      </BorderedGrid>
    </ContentContainer>
  )
}
