import { ReadBookButton, BuyNowButton, ReadFreeBookButton } from '@!/Store/Common/components/BookList/Button'

export const PrimaryActionButton = ({ volume }) => {
  return (
    <>
      {volume.purchased ? (
        <ReadBookButton volume={volume} />
      ) : volume.price === 0 ? (
        <ReadFreeBookButton volume={volume} />
      ) : (
        <BuyNowButton volume={volume}>今すぐ購入する</BuyNowButton>
      )}
    </>
  )
}
