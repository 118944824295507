import { PropTypes } from 'prop-types'

import { HStack, Text, Image } from '@chakra-ui/react'

import { buildAssetUrlString } from '@!/Util'

import PointPng from '@images/point.png'

export const PointOwned = ({ pointCount }) => {
  return (
    <HStack spacing={0}>
      <Image htmlWidth={14} htmlHeight={14} src={buildAssetUrlString(PointPng)} />
      <Text fontSize="2xs" as="span" fontWeight="semibold" ml={1}>
        {pointCount.toLocaleString()}
      </Text>
    </HStack>
  )
}

PointOwned.propType = {
  pointCount: PropTypes.number.isRequired,
}
