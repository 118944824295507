import { useState, useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { bookshelfApi } from '@!/Api/user'
import { userBookshelfKeys } from '@!/Api/queryKeys'

export const useBookshelf = (userHash) => {
  const [searchKeyword, setSearchKeyword] = useState('')
  const [sortType, setSortType] = useState('buy')

  const bookshelf = useQuery({
    queryKey: userBookshelfKeys.bookshelf(userHash),
    queryFn: async () => {
      if (userHash) {
        return await bookshelfApi()
      }
      return []
    },
  })

  const filteredBooks = useMemo(() => {
    if (!bookshelf.data || !searchKeyword) return bookshelf.data || []

    return bookshelf.data.filter((book) => book.title_name.indexOf(searchKeyword) !== -1)
  }, [bookshelf.data, searchKeyword])

  const sortedBooks = useMemo(() => {
    if (!filteredBooks) return []

    return [...filteredBooks].sort((a, b) => {
      if (sortType === 'buy') {
        if (a.latest_purchased_at > b.latest_purchased_at) return -1
        if (a.latest_purchased_at < b.latest_purchased_at) return 1
        return 0
      } else {
        if (a.title_name > b.title_name) return 1
        if (a.title_name < b.title_name) return -1
        return 0
      }
    })
  }, [filteredBooks, sortType])

  const handleSearch = (keyword) => {
    setSearchKeyword(keyword)
  }

  const changeSortType = (type) => {
    setSortType(type)
  }

  return {
    ...bookshelf,
    sortedBooks,
    searchKeyword,
    sortType,
    handleSearch,
    changeSortType,
  }
}
