import PropTypes from 'prop-types'
import { Text, Link, Box } from '@chakra-ui/react'
import { useAtom } from 'jotai'
import { safeSearchAtom } from '@!/atoms/safeSearchAtom'

export const SafeSearchText = ({ showWarningText = false, handleMenuOpen }) => {
  const [isEnabled] = useAtom(safeSearchAtom)

  if (!isEnabled || !showWarningText) return null

  const handleClick = (event) => {
    event.preventDefault()
    handleMenuOpen(true)
  }

  return (
    <Box width="345px" height="17px" top="248px" left="16px" display="flex" mt={2}>
      <Text fontSize="3xs" fontWeight="300" lineHeight="16.5px">
        ※
      </Text>
      <Link onClick={handleClick} color="blue.500" textDecoration="underline" cursor="pointer" fontSize="3xs" fontWeight="600" lineHeight="14px">
        セーフサーチの設定
      </Link>
      <Text fontSize="3xs" fontWeight="300" lineHeight="16.5px">
        により、表示されなかった作品があります。
      </Text>
    </Box>
  )
}

SafeSearchText.propTypes = {
  showWarningText: PropTypes.bool,
  handleMenuOpen: PropTypes.func.isRequired,
}
