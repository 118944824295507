import { Box, Text, Heading, Link } from '@chakra-ui/react'

const AnnouncementContent = ({ title, startsAt, message, onPageBack }) => {
  return (
    <Box p={4}>
      <Heading as="h1" size="md" py={2}>
        {title}
      </Heading>
      <Text fontSize="sm" textAlign="right" py={2}>{`${startsAt.getFullYear()}/${startsAt.getMonth() + 1}/${startsAt.getDate()}`}</Text>
      <Box dangerouslySetInnerHTML={{ __html: message }} py={2} />
      <Link onClick={onPageBack} fontSize="sm">
        {'< 戻る'}
      </Link>
    </Box>
  )
}

export default AnnouncementContent
