import { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { Link, Stack, Text, useToast } from '@chakra-ui/react'

import { useMutation } from '@tanstack/react-query'

import { EmailLogin } from '@!/User/Login/components'
import { loginEmailApi } from '@!/Api/user'
import { CircularProgressModal } from '@!/components/Modal'
import { useJumpLastUrl, useResetByLogin } from '@!/Hooks'

export const LoginForm = () => {
  const jumpLastUrl = useJumpLastUrl()
  const toast = useToast()
  const [isDisabled, setDisabled] = useState(false)
  const [formErrorMessage, setFormErrorMessage] = useState('')
  const executeResetData = useResetByLogin()

  const mutation = useMutation({
    mutationFn: loginEmailApi,
    onMutate: () => {
      setDisabled(true)
      if (formErrorMessage) {
        setFormErrorMessage('')
      }
    },
    onSuccess: () => {
      executeResetData()

      showSuccessMessage()
    },
    onError: (error) => {
      handleErrorMessage(error)
      setDisabled(false)
    },
  })

  const handleErrorMessage = (error) => {
    setFormErrorMessage(error.message)
  }

  const handleLogin = ({ email, password }) => {
    mutation.mutate({
      email: email,
      password: password,
    })
  }

  const showSuccessMessage = () => {
    toast({
      title: '成功',
      description: 'ログインが完了しました。',
      status: 'success',
      duration: 2000,
      isClosable: true,
      onCloseComplete: () => {
        jumpLastUrl()
      },
    })
  }

  return (
    <Stack spacing={0} my={3}>
      <Text textAlign="center" fontSize="sm" my={2}>
        マンガBANG（アプリ）でご利用しているアカウントは、
        <br />
        マンガBANGブックスでもご利用いただけます。
      </Text>
      <EmailLogin formErrorMessage={formErrorMessage} isDisabled={isDisabled} handleLogin={handleLogin} />
      <CircularProgressModal isOpen={mutation.isPending} />
      <Link textAlign="center" as={RouterLink} to="password_reset" my={2}>
        パスワードをお忘れの方はこちら
      </Link>
    </Stack>
  )
}
