import { useQuery } from '@tanstack/react-query'

import { getAnnouncemetDetailApi } from '@!/Api/announcement'
import { announcementsKeys } from '@!/Api/queryKeys'

export function useAnnouncementDetail(announcementId) {
  return useQuery({
    queryKey: announcementsKeys.announcementDetail(announcementId),
    queryFn: async () => {
      if (announcementId) {
        return await getAnnouncemetDetailApi(announcementId)
      }
    },
  })
}
