import { VStack, Text, Icon } from '@chakra-ui/react'
import { FaQuestion } from 'react-icons/fa'
import { PrimaryButton } from '@!/components/Button'

export const Error404 = () => {
  return (
    <VStack spacing={4} pt="12" px="4">
      <Icon as={FaQuestion} fontSize="5xl" color="primary.500" />
      <Text fontWeight="bold">ページが見つかりません。</Text>
      <Text fontSize="2xs" my="2">
        お探しのページは存在しないURL、または削除された可能性があります。
      </Text>
      <PrimaryButton as="a" href="/" maxW="300px">
        トップへ戻る
      </PrimaryButton>
    </VStack>
  )
}
