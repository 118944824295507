import { useQueryClient } from '@tanstack/react-query'

import { savePreviousPaymentMethod } from '@!/Util/localStorageData'

export const useResetByLogin = () => {
  const queryClient = useQueryClient()

  const executeResetData = () => {
    // prefix: user のクエリーを無効化
    queryClient.invalidateQueries({ queryKey: ['user'] })
    // 別ユーザーの情報の場合があるのでリセット
    savePreviousPaymentMethod(null)
  }

  return executeResetData
}
