import { useQuery } from '@tanstack/react-query'
import { couponsApi } from '@!/Api/user'
import { userCouponKeys } from '@!/Api/queryKeys'

export const useCoupons = (userHash) => {
  return useQuery({
    queryKey: userCouponKeys.coupons(userHash),
    queryFn: async () => {
      if (userHash) {
        try {
          return await couponsApi()
        } catch (error) {
          console.log(error)
          return []
        }
      }

      return []
    },
  })
}
