import { useQuery } from '@tanstack/react-query'

import { volumesApi } from '@!/Api/storeBook'
import { volumesKeys } from '@!/Api/queryKeys'

export function useVolumes(bookTitleId) {
  return useQuery({
    queryKey: volumesKeys.volumes(bookTitleId),
    queryFn: async () => {
      return await volumesApi(bookTitleId)
    },
  })
}
