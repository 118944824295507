import { VStack, Text, Icon } from '@chakra-ui/react'
import { FaExclamation } from 'react-icons/fa'

export const Error500 = () => {
  return (
    <VStack spacing={4} pt="12" px="4">
      <Icon as={FaExclamation} fontSize="5xl" color="primary.500" />
      <Text fontWeight="bold">ぺージが表示できません。</Text>
      <Text fontSize="2xs" my="2">
        サーバーで問題が発生しました。しばらくお待ちください。
      </Text>
    </VStack>
  )
}
