import { useAtom } from 'jotai'
import { tokenApiUrlAtom, shopIdAtom, cardInfoAtom, newCardInfoAtom, newCardTokenAtom } from '@!/atoms/paymentAtom'
import { getGmoToken } from '@!/Store/Common/util/payment'
import { isBlank } from '@!/Util'

export const useGmoPayment = () => {
  const [tokenApiUrl] = useAtom(tokenApiUrlAtom)
  const [shopId] = useAtom(shopIdAtom)
  const [cardInfo] = useAtom(cardInfoAtom)
  const [, setNewCardInfo] = useAtom(newCardInfoAtom)
  const [, setNewCardToken] = useAtom(newCardTokenAtom)

  const checkGmoResponse = (gmoResponse) => {
    if (gmoResponse.resultCode == '000') {
      return {
        token: gmoResponse.tokenObject.token,
        maskedCardNo: gmoResponse.tokenObject.maskedCardNo,
      }
    }

    // 000: トークン取得正常終了
    // 100: カード番号必須チェックエラー
    // 101: カード番号フォーマットエラー (数字以外を含む)
    // 102: カード番号フォーマットエラー (10-16 桁の範囲外)
    // 110: 有効期限必須チェックエラー
    // 111: 有効期限フォーマットエラー (数字以外を含む)
    // 112: 有効期限フォーマットエラー (6 又は 4 桁以外)
    // 113: 有効期限フォーマットエラー (月が 13 以上)
    // 121: セキュリティコードフォーマットエラー (数字以外を含む)
    // 122: セキュリティコード桁数エラー
    // 131: 名義人フォーマットエラー (半角英数字、一部の記号以外を含む)
    // 132: 名義人フォーマットエラー (51 桁以上)
    switch (gmoResponse.resultCode) {
      case 100:
      case 101:
      case 102:
        return { message: 'カード番号が正しくありません。', field: 'cardNumber' }
      case 110:
      case 111:
      case 112:
      case 113:
        return { message: '有効期限が正しくありません。', field: 'expirationYear' }
      case 121:
      case 122:
        return { message: 'セキュリティーコードが正しくありません。', field: 'securityCode' }
      case 131:
      case 132:
        return { message: '名義が正しくありません。', field: 'cardholderName' }
      default:
        return { message: 'エラーが発生しました。', field: 'root' }
    }
  }

  const handleGetGmoToken = async (data, setError) => {
    const params = {
      cardno: data.cardNumber,
      holdername: data.cardName,
      expire: `${data.expireYear}${data.expireMonth}`,
      securitycode: data.securityCode,
    }

    try {
      const gmoResponse = await getGmoToken(tokenApiUrl, shopId, params)
      const gmoResult = checkGmoResponse(gmoResponse)

      if (gmoResult.token) {
        setNewCardToken(gmoResult.token)
        setNewCardInfo({
          number: gmoResult.maskedCardNo,
        })

        const update = !isBlank(cardInfo) ? true : false
        return { success: true, token: gmoResult.token, isUpdate: update }
      } else {
        setError(gmoResult.field, { type: 'manual', message: gmoResult.message })
        return { success: false }
      }
    } catch {
      setError('root', { type: 'manual', message: 'エラーが発生しました。' })
      return { success: false }
    }
  }

  return { handleGetGmoToken }
}
