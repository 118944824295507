import { Stack } from '@chakra-ui/react'
import { NewScrollListTitles as ScrollableList, SectionTitle } from '@!/Store/Common/components/'

/* 横スクロール型の新刊リスト */
export const ScrollableNewBookList = ({ newBooks, tabName }) => {
  const sectionTitle = '待望の新着コミックス'
  const baseUrl = '/store/new_books'
  const url = tabName === 'home' ? baseUrl : `${baseUrl}?genre_name=${tabName}`

  return (
    <Stack spacing={0} mx={4} my={3}>
      <SectionTitle title={sectionTitle} url={url} tabName={tabName} />
      <ScrollableList titles={newBooks} type="book" sectionTitle={`${tabName}_${sectionTitle}`} />
    </Stack>
  )
}
