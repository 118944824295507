import React from 'react'
import PropTypes from 'prop-types'
import { HStack, Box, Link, VStack, Text, Flex } from '@chakra-ui/react'
import { CoverImage } from '@!/Store/Common/components'
import { SampleButton } from '@!/Store/Common/components/BookList/Button'

export const ListItem = ({ bookTitle }) => {
  const linkUrl = `/store/books?book_title_id=${bookTitle.mddc_id}`

  // 作者名は5人まで表示
  const authorNames = bookTitle.author_name ? bookTitle.author_name.split(',').slice(0, 5) : null

  const linkStyles = {
    color: 'label.primary',
    fontWeight: '300',
    textDecoration: 'none',
    cursor: 'pointer',
  }

  return (
    <HStack spacing={0} w="100%" py="16px" alignItems="flex-start">
      <Link {...linkStyles} href={linkUrl}>
        <CoverImage width="90px" src={bookTitle.image_url} alt={bookTitle.name} />
      </Link>
      <VStack spacing={0} pl="16px" flex="1" alignItems="start" fontSize="3xs" fontWeight="300" lineHeight="16.5px">
        <Link {...linkStyles} mb="8px" fontSize="md" fontWeight="600" lineHeight="22px" href={linkUrl}>
          {bookTitle.name}
        </Link>
        {authorNames && (
          <HStack spacing={0} mb="4px" alignItems="flex-start">
            <Text>著者 :</Text>
            <Box flex="1">
              {authorNames
                .map((authorName, index) => (
                  <Link key={`list-item-author-name-${index}`} {...linkStyles} href={`/store/search/author/${encodeURIComponent(authorName)}`}>
                    {authorName}
                  </Link>
                ))
                .reduce((prev, curr) => [prev, '、', curr])}
            </Box>
          </HStack>
        )}
        {bookTitle.publisher_name && (
          <HStack spacing={0} mb="4px" alignItems="flex-start">
            <Text>出版社 :</Text>
            <Box flex="1">
              <Link {...linkStyles} href={`/store/search/publisher/${encodeURIComponent(bookTitle.publisher_name)}`}>
                {bookTitle.publisher_name}
              </Link>
            </Box>
          </HStack>
        )}
        {bookTitle.genre_name && (
          <HStack spacing={0} mb="4px" alignItems="flex-start">
            <Text>ジャンル :</Text>
            <Box flex="1">
              <Link {...linkStyles} href={`/store/search/category/${encodeURIComponent(bookTitle.genre_name)}`}>
                {bookTitle.genre_name}
              </Link>
            </Box>
          </HStack>
        )}
        {bookTitle.label_name && (
          <HStack spacing={0} mb="4px" alignItems="flex-start">
            <Text>レーベル :</Text>
            <Box flex="1">
              <Link {...linkStyles} href={`/store/search/label/${encodeURIComponent(bookTitle.label_name)}`}>
                {bookTitle.label_name}
              </Link>
            </Box>
          </HStack>
        )}
        <Flex w="100%" justifyContent="flex-end">
          {bookTitle.first_volume.sample_book_id && bookTitle.first_volume.price !== 0 && !bookTitle.first_volume.purchased && (
            <Box w="124px" h="37px">
              <SampleButton
                distributionId={bookTitle.first_volume.distribution_id}
                sampleBookId={bookTitle.first_volume.sample_book_id}
                rating={bookTitle.rating}
                price={bookTitle.first_volume.price}
                isPurchased={bookTitle.first_volume.purchased}
              />
            </Box>
          )}
        </Flex>
      </VStack>
    </HStack>
  )
}

ListItem.propType = {
  bookTitle: PropTypes.shape({
    mddc_id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    image_url: PropTypes.string.isRequired,
    author_name: PropTypes.string,
    publisher_name: PropTypes.string,
    genre_name: PropTypes.string,
    first_volume: PropTypes.object.isRequired,
  }).isRequired,
}
