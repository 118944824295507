import { PropTypes } from 'prop-types'
import { Stack, Link, Heading } from '@chakra-ui/react'

import { encodeURIPath } from '@!/Util/string'
import { TagSearchList } from '@!/Store/Common/components/TagSearchList'

/* 各画面の下部に表示するタグ検索用のリンク一覧 */
export const TagSearchBox = ({ searchTags, genreName }) => {
  const buildSearchUrl = (type, tag) => {
    const baseUrl = `/store/search/${type}/${encodeURIPath(tag)}`
    if (genreName && (type === 'label' || type === 'magazine')) {
      return `${baseUrl}?genre_name=${genreName}`
    }
    return baseUrl
  }

  return (
    <Stack spacing={0} pb={6}>
      {searchTags.map((searchTag, index) =>
        searchTag.type === 'kana_tables' ? (
          <Link key={index} href="/store/search/kana_tables" color="label.primary" mx={4} my={3} textUnderlineOffset="0.2em">
            <Heading as="h2" fontSize="lg">
              50音漫画検索
            </Heading>
          </Link>
        ) : (
          <TagSearchList key={index} title={searchTag.title} tags={searchTag.tags} buildSearchUrl={(tag) => buildSearchUrl(searchTag.type, tag)} />
        ),
      )}
    </Stack>
  )
}

TagSearchBox.propTypes = {
  searchTags: PropTypes.array.isRequired,
  genreName: PropTypes.string,
}
