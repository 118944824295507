import { useQuery } from '@tanstack/react-query'
import { newBooksKeys } from '@!/Api/queryKeys'
import { getNewBooksApi } from '@!/Api/newBooks'
import { useAtom } from 'jotai'
import { safeSearchAtom } from '@!/atoms/safeSearchAtom'

export const useNewBooks = (genreName, page, perPage) => {
  const [safeSearch] = useAtom(safeSearchAtom)

  return useQuery({
    queryKey: newBooksKeys.filtered(safeSearch, genreName, page, perPage),
    queryFn: async () => {
      return await getNewBooksApi(safeSearch, genreName, page, perPage)
    },
    keepPreviousData: true,
  })
}
