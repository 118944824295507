import { useState, useMemo } from 'react'
import { useMutation } from '@tanstack/react-query'
import queryString from 'query-string'

import { purchasedBookDownloadApi, freeBookDownloadApi, sampleBookDownloadApi } from '@!/Api/storeBook'
import { encodeURIPath } from '@!/Util/string'

export const useReadBook = (readType) => {
  const [redirectUrl, setRedirectUrl] = useState(null)
  const [apiError, setApiError] = useState(null)

  const apiFunc = useMemo(() => {
    switch (readType) {
      case 'purchased':
        return purchasedBookDownloadApi
      case 'free':
        return freeBookDownloadApi
      case 'sample':
        return sampleBookDownloadApi
      default:
        null
    }
  }, [readType])

  const buildBookDownloadUrl = (responseDownloadUrl) => {
    const callBackUrl = encodeURIPath(window.location.href, false)
    const donwloadUrlObj = queryString.parseUrl(responseDownloadUrl, { parseFragmentIdentifier: true })
    donwloadUrlObj.query.url = callBackUrl
    return queryString.stringifyUrl(donwloadUrlObj)
  }

  const readBookMutation = useMutation({
    mutationFn: (params) => apiFunc(params),
    onSuccess: (data) => {
      if (data && data.download_url) {
        setRedirectUrl(buildBookDownloadUrl(data.download_url))
        setApiError(null)
      } else {
        setApiError('server error')
      }
    },
    onError: (error) => {
      if (error.data?.error) {
        setApiError(error.data.error)
      } else {
        setApiError('server error')
      }
    },
  })

  return [readBookMutation, redirectUrl, apiError, setApiError]
}
