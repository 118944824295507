import { useState, useEffect, useMemo } from 'react'
import { useAtom } from 'jotai'
import { getDevice } from '@!/Util'
import { userHashAtom } from '@!/atoms/userInfoAtom'
import { Banner } from './Banner'
import loggedInPointbackSpBannerImage from '@images/logged_in_point_back_sp_banner.webp'
import loggedInPointbackPcBannerImage from '@images/logged_in_point_back_pc_banner.webp'
import notLoggedInPointbackSpBannerImage from '@images/not_logged_in_point_back_sp_banner.webp'
import notLoggedInPointbackPcBannerImage from '@images/not_logged_in_point_back_pc_banner.webp'
import slimNoImageBanner from '@images/slim_noimage.webp'

export const PointBackBanner = (props) => {
  const [userHash] = useAtom(userHashAtom)
  const device = useMemo(() => getDevice(), [])
  const [banner, setBanner] = useState(null)

  useEffect(() => {
    if (device === 'pc') {
      setBanner(userHash ? loggedInPointbackPcBannerImage : notLoggedInPointbackPcBannerImage)
    } else {
      setBanner(userHash ? loggedInPointbackSpBannerImage : notLoggedInPointbackSpBannerImage)
    }
  }, [userHash, device])

  if (!banner) return null

  return <Banner src={banner} fallbackSrc={slimNoImageBanner} href="/event" alt="初回限定90%OFF、全作品対象40%ポイント還元キャンペーン" {...props} />
}
