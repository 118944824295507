import React from 'react'
import { Menu, MenuButton, MenuList, MenuItem, Text, HStack } from '@chakra-ui/react'
import { LuArrowUpDown } from 'react-icons/lu'

export default function SortBlock({ sortType, changeSortType }) {
  return (
    <Menu autoSelect={false}>
      <MenuButton color="label.primary" fontWeight="semibold">
        <HStack spacing={0} fontSize="3xs">
          <LuArrowUpDown />
          <Text pl={1}>並び替え</Text>
        </HStack>
      </MenuButton>
      <MenuList fontSize="3xs">
        <MenuItem onClick={() => changeSortType('buy')} bg={sortType === 'buy' ? 'bg.tertiary' : null} fontWeight={sortType === 'buy' ? 'semibold' : null}>
          購入順
        </MenuItem>
        <MenuItem
          onClick={() => changeSortType('title')}
          bg={sortType === 'title' ? 'bg.tertiary' : null}
          fontWeight={sortType === 'title' ? 'semibold' : null}
        >
          作品順
        </MenuItem>
      </MenuList>
    </Menu>
  )
}
