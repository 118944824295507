import { useState, useEffect } from 'react'
import { PropTypes } from 'prop-types'

import { NormalButton } from '@!/components/Button'
import { CircularProgressModal, YesNoPopupModal, ConfirmPopupModal } from '@!/components/Modal'
import { storeBookColophonUrl } from '@!/Util/location'
import { useReadBook } from '@!/Hooks'

export const SampleButton = ({ distributionId, sampleBookId, rating, price, isPurchased }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [readSampleBookMutation, redirectUrl, apiError, setApiError] = useReadBook('sample')

  useEffect(() => {
    if (!redirectUrl) return

    window.location.href = redirectUrl
  }, [redirectUrl])

  const executeAction = async () => {
    // colophonUrlには本編のIDを渡す
    const colophonUrl = storeBookColophonUrl(distributionId, true)
    readSampleBookMutation.mutate({ bookId: sampleBookId, colophonUrl: colophonUrl })
  }

  const handleButtonAction = (event) => {
    event.preventDefault()

    if (rating > 2) {
      setModalOpen(true)
    } else {
      executeAction()
    }
  }

  const handleCancelDialog = () => {
    setModalOpen(false)
  }

  const handleWarningSubmit = () => {
    setModalOpen(false)
    executeAction()
  }

  const handleApiError = () => {
    console.log(apiError)
    setApiError(null)
  }

  return (
    <>
      <NormalButton isDisabled={!sampleBookId || price === 0 || isPurchased} onClick={(event) => handleButtonAction(event)} width="100%" height="37px">
        無料立ち読み
      </NormalButton>
      <YesNoPopupModal
        title="この作品には性的・暴力的な表現が一部含まれている可能性があります。ご了承の上閲覧しますか?"
        handleCancel={() => handleCancelDialog()}
        handleSubmit={() => handleWarningSubmit()}
        cancelTitle="キャンセル"
        submitTitle="はい"
        isOpen={modalOpen}
      />
      <ConfirmPopupModal text="エラーが発生しました。" handlePopup={() => handleApiError()} isOpen={!!apiError} />
      <CircularProgressModal isOpen={readSampleBookMutation.isPending} />
    </>
  )
}

SampleButton.propType = {
  distributionId: PropTypes.string.isRequired,
  sampleBookId: PropTypes.string.isRequired,
  rating: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  isPurchased: PropTypes.bool.isRequired,
}
