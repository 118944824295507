import { useEffect } from 'react'
import { useAtom } from 'jotai'
import { useAnnouncements } from '@!/Hooks'
import { breadcrumbAtom } from '@!/atoms/breadcrumbAtom'
import { CircularProgressModal } from '@!/components/Modal'
import AnnouncementList from '@!/Announcements/components/AnnouncementList'

const Announcements = () => {
  const { data: announcements, isLoading } = useAnnouncements()
  const [, setBreadcrumb] = useAtom(breadcrumbAtom)

  useEffect(() => {
    setBreadcrumb([{ name: 'マンガBANGブックス', ref: '/' }, { name: 'お知らせ' }])
  }, [])

  return (
    <>
      <CircularProgressModal isOpen={isLoading} />
      <AnnouncementList announcements={announcements} />
    </>
  )
}

export default Announcements
