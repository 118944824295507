import { useQuery } from '@tanstack/react-query'
import { getPurchaseHistoriesApi } from '@!/Api/storeBookPurchase'
import { purchaseHistoriesKeys } from '@!/Api/queryKeys'

export const usePurchaseHistories = (userHash) => {
  return useQuery({
    queryKey: purchaseHistoriesKeys.purchaseHistories(userHash),
    queryFn: async () => await getPurchaseHistoriesApi(),
    enabled: !!userHash,
  })
}
