import { useState, useEffect } from 'react'
import { useAtom } from 'jotai'
import { userHashAtom } from '@!/atoms/userInfoAtom'
import { cartItemsAtom, createCartItemAtom, deleteCartItemAtom } from '@!/atoms/cartItemsAtom'
import { saveCartItemLoggedIn, saveCartItemNotLoggedIn } from '@!/Util/localStorageData'
import { Config } from '@!/config/config'
import { useCreateCartItem } from '@!/Hooks'

export const useAddCart = (detail) => {
  const [userHash] = useAtom(userHashAtom)
  const [cartItems] = useAtom(cartItemsAtom)
  const [, createCartItem] = useAtom(createCartItemAtom)
  const [, deleteCartItem] = useAtom(deleteCartItemAtom)
  const [showConfirmPopup, setShowConfirmPopup] = useState(false)
  const [confirmMessage, setConfirmMessage] = useState('')

  const [createCartItemMutation] = useCreateCartItem(userHash, detail)

  const [maximumNumberOfCarts, setMaximumNumberOfCarts] = useState(Config.maximumNumberOfCartsNotLoggedIn)

  useEffect(() => {
    userHash ? setMaximumNumberOfCarts(Config.maximumNumberOfCartsLoggedIn) : setMaximumNumberOfCarts(Config.maximumNumberOfCartsNotLoggedIn)
  }, [userHash])

  useEffect(() => {
    if (!createCartItemMutation.isSuccess) return
    updateCartItem()
  }, [createCartItemMutation.isSuccess])

  useEffect(() => {
    if (!createCartItemMutation.isError) return
    openConfirmPopup('カートへの追加に失敗しました。')
  }, [createCartItemMutation.isError])

  const openConfirmPopup = (message) => {
    setConfirmMessage(message)
    setShowConfirmPopup(true)
  }

  const updateCartItem = () => {
    if (userHash) {
      saveCartItemLoggedIn(detail)
    } else {
      saveCartItemNotLoggedIn(detail)
    }

    cartItems.forEach((cartItem) => {
      if (cartItem.distribution_id === detail.distribution_id) {
        deleteCartItem()
      }
    })
    createCartItem(detail)
  }

  const handleAddCartItem = async (event) => {
    event.preventDefault()

    if (cartItems.length >= maximumNumberOfCarts) {
      openConfirmPopup(`カートに追加できるのは最大${maximumNumberOfCarts}件です。`)
      return
    }

    if (userHash) {
      createCartItemMutation.mutate()
      return
    }

    updateCartItem()
  }

  return {
    handleAddCartItem,
    showConfirmPopup,
    confirmMessage,
    closeConfirmPopup: () => setShowConfirmPopup(false),
  }
}
