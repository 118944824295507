import { VStack, StackDivider } from '@chakra-ui/react'
import { BookListItem } from '@!/Store/Common/components/BookList'

export const BookList = ({ volumes, rating }) => {
  return (
    <VStack divider={<StackDivider style={{ margin: '1rem 0' }} />} spacing={0}>
      {volumes.map((volume, index) => {
        return <BookListItem key={`${volume.distribution_id}-${index}`} volume={volume} rating={rating} />
      })}
    </VStack>
  )
}
