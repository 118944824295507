import { AppProvider } from '@!/providers'
import { usePaymentSettings } from '@!/Hooks'
import BookPurchase from '@!/Store/Purchase/containers/BookPurchase'

const BookPurchaseApp = (props) => {
  const jotaiStore = usePaymentSettings({
    tokenApiUrl: props.token_api_url,
    shopId: props.shop_id,
  })

  return (
    <AppProvider jotaiStore={jotaiStore}>
      <BookPurchase bookId={props.book_id} tokenApiUrl={props.token_api_url} shopId={props.shop_id} />
    </AppProvider>
  )
}

export default BookPurchaseApp
