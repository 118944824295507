import { PropTypes } from 'prop-types'

import { Box, Circle } from '@chakra-ui/react'

export const CountBadge = ({ count }) => {
  const overflow = 99
  const text = count > overflow ? `${overflow}+` : String(count)
  const width = count > overflow ? '26px' : '20px'

  return (
    <Circle h="20px" w={width} color="label.white" bg="label.accent">
      <Box as="span" fontWeight="600" fontSize="3xs">
        {text}
      </Box>
    </Circle>
  )
}

CountBadge.propType = {
  count: PropTypes.number.isRequired,
}
