import { PropTypes } from 'prop-types'

import { FilterBox } from '@!/Store/Common/components'

export const BookshelfSerachForm = ({ keyword, handleSearch }) => {
  return <FilterBox defaultValue={keyword} handleSearch={handleSearch} placeholder="本棚内の作品を検索する" />
}

BookshelfSerachForm.propType = {
  keyword: PropTypes.string,
  handleSearch: PropTypes.func.isRequired,
}
