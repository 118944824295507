import { useEffect } from 'react'
import { useAtom } from 'jotai'
import { Box, Tabs, TabList, Tab, TabPanel, TabPanels, Divider, Stack } from '@chakra-ui/react'

import { CurrentCoinInfo } from '@!/User/common/components'
import { CoinHistoriesListItem } from '@!/User/CoinHistories/components'
import { useCoinHistories } from '@!/Hooks'
import { CircularProgressModal, ConfirmPopupModal } from '@!/components/Modal'
import { breadcrumbAtom } from '@!/atoms/breadcrumbAtom'

const CoinHistories = () => {
  const [coinHistoriesMutation] = useCoinHistories()
  const [, setBreadcrumb] = useAtom(breadcrumbAtom)

  useEffect(() => {
    coinHistoriesMutation.mutate()
    setBreadcrumb([{ name: 'マンガBANGブックス', ref: '/' }, { name: 'コイン購入履歴' }])
  }, [])

  const tabStyles = {
    w: '50%',
    p: '6px',
    borderWidth: '1px',
    borderRadius: '7px',
    fontWeight: '600',
    fontSize: '13px',
    lineHeight: '20px',
    _selected: {
      bg: 'label.white',
    },
  }

  return (
    <Box py="30px">
      <CircularProgressModal isOpen={coinHistoriesMutation.isPending} />
      <ConfirmPopupModal
        text="コイン購入履歴の取得に失敗しました。"
        handlePopup={() => (window.location.href = '/user/coin_histories')}
        isOpen={coinHistoriesMutation.isError}
      />
      {coinHistoriesMutation.isSuccess ? (
        <>
          <CurrentCoinInfo coinCounts={coinHistoriesMutation.data} />
          <Tabs variant="unstyled" mt="26px">
            <TabList mx="16px" mb="24px" bg="bg.tertiary" borderColor="bg.tertiary" borderWidth="1px" borderRadius="7px">
              <Tab {...tabStyles}>購入履歴</Tab>
              <Tab {...tabStyles}>使用履歴</Tab>
            </TabList>
            <TabPanels>
              <TabPanel p="0">
                <Divider />
                <Stack divider={<Divider />}>
                  {coinHistoriesMutation.data.coin_purchase_histories.map((value, index) => (
                    <CoinHistoriesListItem key={`coin-purchase-histories-${index}`} item={value} />
                  ))}
                </Stack>
                <Divider />
              </TabPanel>
              <TabPanel p="0">
                <Divider />
                <Stack divider={<Divider />}>
                  {coinHistoriesMutation.data.coin_use_histories.map((value, index) => (
                    <CoinHistoriesListItem key={`coin-use-histories-${index}`} item={value} />
                  ))}
                </Stack>
                <Divider />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </>
      ) : null}
    </Box>
  )
}

export default CoinHistories
