import PropTypes from 'prop-types'
import { Text, Flex, Box } from '@chakra-ui/react'

const PurchaseSummary = ({ totalPrice, basePrice = null, isCouponApplied = false }) => {
  return (
    <Box fontSize="md" fontWeight="bold">
      {isCouponApplied && (
        <Flex justifyContent="space-between" alignItems="center">
          <Text>合計</Text>
          <Flex alignItems="baseline">
            <Text color="label.primary" textDecoration="line-through">
              {basePrice.toLocaleString()} 円(税込)
            </Text>
          </Flex>
        </Flex>
      )}
      <Flex justifyContent="space-between" alignItems="center">
        {isCouponApplied ? <Text color="label.accent">クーポン適用価格</Text> : <Text>合計</Text>}
        <Flex alignItems="baseline">
          <Text fontSize="2xl" color="label.accent">
            {totalPrice.toLocaleString()}
          </Text>
          <Text color="label.accent">&nbsp;円(税込)</Text>
        </Flex>
      </Flex>
    </Box>
  )
}

PurchaseSummary.propTypes = {
  totalPrice: PropTypes.number.isRequired,
  basePrice: PropTypes.number,
  isCouponApplied: PropTypes.bool,
}

export default PurchaseSummary
