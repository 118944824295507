import { useEffect, useState } from 'react'
import { useAtom } from 'jotai'

import { usePointBackMeasure, useCalculatePointBack, useRemoveCartItems, useNavigationHandler } from '@!/Hooks'
import { cartItemsAtom, numberOfCartItemsAtom } from '@!/atoms/cartItemsAtom'
import { userHashAtom } from '@!/atoms/userInfoAtom'
import { getCartItemsLoggedIn, getCartItemsNotLoggedIn, removeCartItemLoggedIn, removeCartItemNotLoggedIn } from '@!/Util/localStorageData'

import Cart from '@!/Store/Cart/components/Cart'
import { CircularProgressModal, ConfirmPopupModal } from '@!/components/Modal'

const CartContainer = () => {
  const [cartItems, setCartItems] = useAtom(cartItemsAtom)
  const [numberOfCartItems] = useAtom(numberOfCartItemsAtom)

  const [userHash] = useAtom(userHashAtom)

  const [showConfirmPopup, setShowConfirmPopup] = useState(false)
  const [isRemoving, setIsRemoving] = useState(false)
  const [removeDistributionIds, setRemoveDistributionIds] = useState(false)
  const [totalPrice, setTotalPrice] = useState(0)

  const { data: pointBackMeasure, isLoading: pointBackMeasureLoading, isError: pointBackMeasureisError } = usePointBackMeasure()
  const [removeCartItemsMutation, redirectUrl, removeApiError] = useRemoveCartItems(userHash)

  const pointBack = useCalculatePointBack(cartItems, pointBackMeasure)

  const handleRemoveCartItems = (distributionIds) => {
    setIsRemoving(true)
    if (userHash) {
      setRemoveDistributionIds(distributionIds)
      removeCartItemsMutation.mutate(distributionIds)
    } else {
      removeCartItemNotLoggedIn(distributionIds)
      setCartItems(getCartItemsNotLoggedIn())
      setTotalPrice(calcTotalPrice())
    }
    setIsRemoving(false)
  }

  const { redirectToLoginWithToast } = useNavigationHandler()
  const goToPurchase = () => {
    if (userHash === undefined) {
      redirectToLoginWithToast()
    } else {
      window.location.href = '/store/purchase'
    }
  }

  useEffect(() => {
    setTotalPrice(calcTotalPrice())
  }, [cartItems])

  useEffect(() => {
    if (!removeCartItemsMutation.isSuccess) return

    removeCartItemLoggedIn(removeDistributionIds)
    setCartItems(getCartItemsLoggedIn())
    setTotalPrice(calcTotalPrice())
  }, [removeCartItemsMutation.isSuccess])

  useEffect(() => {
    if (!removeApiError) return

    if (redirectUrl) {
      window.location.href = redirectUrl
    } else {
      setShowConfirmPopup(true)
    }
  }, [removeApiError])

  const calcTotalPrice = () => {
    return cartItems.reduce((total, cartItem) => {
      return total + cartItem.price
    }, 0)
  }

  return (
    <>
      <CircularProgressModal isOpen={pointBackMeasureLoading || removeCartItemsMutation.isPending || isRemoving} />
      {pointBackMeasureLoading ? null : (
        <>
          <Cart
            books={cartItems}
            cartCount={numberOfCartItems}
            pointBackMeasure={pointBackMeasure}
            goToPurchase={goToPurchase}
            handleRemoveCartItems={handleRemoveCartItems}
            totalPrice={totalPrice}
            pointBack={pointBack}
          />
          <ConfirmPopupModal
            text="作品の削除に失敗しました。"
            isOpen={showConfirmPopup}
            handlePopup={() => {
              setShowConfirmPopup(false)
            }}
          />
          <ConfirmPopupModal
            text="エラーが発生しました。"
            isOpen={pointBackMeasureisError}
            handlePopup={() => {
              // pointBackMeasureApiがエラーを返すのはサーバー側のエラーのみなので一旦トップページに返す
              window.location.href = '/'
            }}
          />
        </>
      )}
    </>
  )
}

export default CartContainer
