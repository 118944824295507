import { useEffect } from 'react'
import StopPiarcyImage from '@images/stop_piracy.webp'
import AbjMarkImage from '@images/abj_mark.webp'
import { useAtom } from 'jotai'
import { breadcrumbAtom } from '@!/atoms/breadcrumbAtom'
import { buildAssetUrlString } from '@!/Util'
import { Box, Image, Text, Heading, Divider, VStack, Link, Button } from '@chakra-ui/react'

export const StopPiracy = () => {
  const [, setBreadcrumb] = useAtom(breadcrumbAtom)

  useEffect(() => {
    setBreadcrumb([{ name: 'マンガBANGブックス', ref: '/' }, { name: '海賊版に関する取り組み' }])
  }, [])

  return (
    <>
      <Image src={buildAssetUrlString(StopPiarcyImage)} alt="STOP!海賊版" width="100%" />
      <Box p={4}>
        <VStack spacing={0}>
          <Heading as="h1" fontSize="md" textAlign="center" fontWeight="normal" lineHeight="1.5">
            マンガBANGブックスは、出版社・著作権者から
            <br />
            <Text as="span" color="label.accent" fontWeight="bold">
              正規に使用許諾を得ている
            </Text>
            <br />
            電子書店・電子書籍配信サービスです。
          </Heading>
          <Box my="8" p="4" bgColor="bg.tertiary" width="100%">
            海賊版サイトでの閲覧・購読は違法行為を助長するだけであり、作者、出版社、書店にその収益は一切還元されません。読者の皆様の閲覧・購読の収益が正しく還元されることが、次の新しいコンテンツを生み出すことにつながります。正規版配信サービスで閲覧・購読をお願いします。
          </Box>
          <Heading as="h2" fontSize="lg" fontWeight="normal" lineHeight="1.5" textAlign="center">
            正規配信サイトマークを取得
          </Heading>
          <Divider borderColor="black" />
          <Image my="4" w="300px" maxW="50%" src={buildAssetUrlString(AbjMarkImage)} alt="ABJマーク" />
          <Text mb="8">
            ＡＢＪマークは、この電子書店・電子書籍配信サービスが、著作権者からコンテンツ使用許諾を得た正規版配信サービスであることを示す登録商標（登録番号
            第６０９１７１３号）です。ABJマークの詳細、ABJマークを掲示しているサービスの一覧はこちら
            <Link href="https://aebs.or.jp/" isExternal>
              https://aebs.or.jp/
            </Link>
          </Text>
          <Heading as="h2" fontSize="lg" fontWeight="normal" lineHeight="1.5" textAlign="center">
            著作権者の皆様へ
          </Heading>
          <Divider borderColor="black" />
          <Text py="4">
            当社は全ての配信作品について出版社様、取次会社様等との間で許諾契約を締結し、配信させて頂いております。
            ご自身の作品の配信許諾契約についてご確認なさりたい場合、お手数ですが、下記にご連絡を頂けますようお願いいたします。
          </Text>
          <Button w="full" as="a" href="mailto:info@support-mangabang.jp?subject=配信許諾契約について" my="6">
            お問い合わせ
          </Button>
        </VStack>
      </Box>
    </>
  )
}
