import { useEffect, useRef, useState } from 'react'
import { useAtom } from 'jotai'
import { useMutation } from '@tanstack/react-query'
import { checkEmailAddress } from '@!/Util/string'
import { CircularProgressModal } from '@!/components/Modal'
import { userHashAtom } from '@!/atoms/userInfoAtom'
import { resetPasswordApi } from '@!/Api/user'
import { Container, Heading, Text, FormControl, FormLabel, Input, Stack } from '@chakra-ui/react'
import { PrimaryButton } from '@!/components/Button'
import { useJumpLastUrl } from '@!/Hooks'

export const PasswordReset = () => {
  const jumpLastUrl = useJumpLastUrl()
  const [userHash] = useAtom(userHashAtom)
  const [resetPasswordStatus, setResetPasswordStatus] = useState(null)
  const [isApiProcessing, setIsApiProcessing] = useState(false)
  const emailRef = useRef(null)

  const resetPasswordMutation = useMutation({
    mutationFn: (email) => resetPasswordApi({ email: email }),
  })

  useEffect(() => {
    if (userHash) {
      jumpLastUrl()
    }
  }, [userHash])

  useEffect(() => {
    if (!resetPasswordMutation.isSuccess) return

    setResetPasswordStatus('success')
    setIsApiProcessing(false)
  }, [resetPasswordMutation.isSuccess])

  useEffect(() => {
    if (!resetPasswordMutation.isError) return

    setResetPasswordStatus('fail')
    setIsApiProcessing(false)
  }, [resetPasswordMutation.isError])

  const reset = (e) => {
    e.preventDefault()

    setIsApiProcessing(true)
    const email = emailRef.current.value
    if (checkEmailAddress(email)) {
      resetPasswordMutation.mutate(email)
    } else {
      setResetPasswordStatus('invalid_parameter')
      setIsApiProcessing(false)
    }
  }

  return (
    <Container p={5} bg="bg.primary" mt={1} boxSizing="border-box">
      <Stack spacing={0}>
        <Heading size="md" as="h2" textAlign="center" mt={5}>
          パスワードリセット
        </Heading>
        {resetPasswordStatus === 'success' ? (
          <Text textAlign="left" fontSize="sm" my={5}>
            ご入力頂いたメールアドレスに新しいパスワードを記載したメールを送らせていただきました。
          </Text>
        ) : (
          <>
            <Text textAlign="center" fontSize="sm" my={5}>
              登録しているメールアドレスを入力してください。
              <br />
              パスワード再発行用のメールを送信します。
            </Text>
            {resetPasswordStatus === 'invalid_parameter' && <Text color="red.500">メールアドレスが正しくありません。</Text>}
            {resetPasswordStatus === 'fail' && <Text color="red.500">パスワードリセット処理に失敗しました。</Text>}
            <CircularProgressModal isOpen={isApiProcessing} />
            <form onSubmit={reset}>
              <FormControl isRequired>
                <FormLabel fontWeight="bold" fontSize="sm">
                  メールアドレス
                </FormLabel>
                <Input ref={emailRef} type="email" bg="bg.secondary" placeholder="example@manga-bang.com" boxSizing="border-box" />
              </FormControl>
              <Text fontSize="2xs" mt={2}>
                @support-mangabang.jp からの受信設定をしてください。
              </Text>
              <PrimaryButton type="submit" my={5}>
                メールを送信
              </PrimaryButton>
            </form>
          </>
        )}
      </Stack>
    </Container>
  )
}

export default PasswordReset
