import { VStack, Link } from '@chakra-ui/react'

export const PaymentMainFooter = () => (
  <VStack mt="22px" pb="31px" fontSize="xs" lineHeight="20px" spacing={0}>
    <Link fontWeight="600" href="/static_page/specified_commercial_transaction">
      特定商取引法の表記
    </Link>
    <Link fontWeight="600" href="/static_page/fund_settlement" mt={4}>
      資金決済法に基づく表示
    </Link>
  </VStack>
)

PaymentMainFooter.propTypes = {}
