import { Box, Text } from '@chakra-ui/react'

export const Copyright = () => {
  return (
    <Box textAlign="center" my={2}>
      <Text color="white" fontSize="2xs">
        COPYRIGHT ©2018 Amazia, Inc. All Rights Reserved.
      </Text>
    </Box>
  )
}
