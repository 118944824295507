import { useEffect, useState } from 'react'
import { useAtom } from 'jotai'
import { Container, VStack, Heading, Text, Divider, HStack, Box, Flex } from '@chakra-ui/react'
import BookshelfItems from '@!/User/Bookshelf/components/BookshelfItems'
import BookshelfList from '@!/User/Bookshelf/components/BookshelfList'
import SortBlock from '@!/User/Bookshelf/components/SortBlock'
import { CircularProgressModal } from '@!/components/Modal'
import { getBookselfDisplayDefault, saveBookselfDisplayDefault } from '@!/Util/localStorageData'
import { breadcrumbAtom } from '@!/atoms/breadcrumbAtom'
import { useBookshelf } from '@!/Hooks'
import { userHashAtom } from '@!/atoms/userInfoAtom'
import { BookshelfSerachForm } from './BookshelfSerachForm'
import { isBlank } from '@!/Util'
import { IoGrid } from 'react-icons/io5'
import { FaListUl } from 'react-icons/fa6'
import { PiLineVerticalBold } from 'react-icons/pi'

const Bookshelf = () => {
  const [userHash] = useAtom(userHashAtom)
  const defaultDisplay = getBookselfDisplayDefault()
  const { isLoading, sortedBooks, searchKeyword, sortType, handleSearch, changeSortType } = useBookshelf(userHash)
  const [viewMode, setViewMode] = useState('grid')
  const [, setBreadcrumb] = useAtom(breadcrumbAtom)

  useEffect(() => {
    setBreadcrumb([{ name: 'マンガBANGブックス', ref: '/' }, { name: '本棚' }])
  }, [])

  useEffect(() => {
    setViewMode(defaultDisplay === 'list' ? 'list' : 'grid')
  }, [defaultDisplay])

  const handleClickBook = (distributionId) => {
    window.location.href = `/store/books/${distributionId}`
  }

  const handleViewModeChange = (mode) => (e) => {
    e.preventDefault()
    setViewMode(mode)
    saveBookselfDisplayDefault(mode)
  }

  const ViewModeButton = ({ mode, label, icon: Icon }) => {
    const isActive = viewMode === mode

    return (
      <HStack spacing={0} color={isActive ? 'label.primary' : 'label.tertiary'} cursor={isActive ? 'default' : 'pointer'} onClick={handleViewModeChange(mode)}>
        <Icon />
        <Text pl={1}>
          {label}
          {!isActive && 'にする'}
        </Text>
      </HStack>
    )
  }

  return (
    <Container py={6} px={4} boxSizing="border-box">
      <CircularProgressModal isOpen={isLoading} />
      <VStack spacing={0} align="stretch">
        <Box>
          <Heading as="h1" fontSize="2xl">
            本棚
          </Heading>
          <Divider my={3} />
          <BookshelfSerachForm keyword={searchKeyword} handleSearch={handleSearch} />
          {!isBlank(sortedBooks) && (
            <Flex justifyContent="space-between" alignItems="center" fontSize="sm" fontWeight="semibold" py={4}>
              <HStack spacing={0} fontSize="3xs">
                <ViewModeButton mode="grid" label="サムネイル表示" icon={IoGrid} />
                <Box px={1}>
                  <PiLineVerticalBold color="#B8B8BD" px={1} />
                </Box>
                <ViewModeButton mode="list" label="リスト表示" icon={FaListUl} />
              </HStack>
              <SortBlock sortType={sortType} changeSortType={changeSortType} />
            </Flex>
          )}
        </Box>

        {!isBlank(sortedBooks) ? (
          viewMode === 'grid' ? (
            <BookshelfItems books={sortedBooks} handleClickBook={handleClickBook} />
          ) : (
            <BookshelfList books={sortedBooks} handleClickBook={handleClickBook} />
          )
        ) : (
          <Text py={10} textAlign="center" fontSize="sm">
            閲覧できる作品がありません。
          </Text>
        )}
      </VStack>
    </Container>
  )
}

export default Bookshelf
