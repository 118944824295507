import { useEffect } from 'react'
import { Box, Text, Heading, Grid, VStack, Flex, Image, Divider, List, ListItem, SimpleGrid } from '@chakra-ui/react'
import { useAtom } from 'jotai'
import { breadcrumbAtom } from '@!/atoms/breadcrumbAtom'
import { buildAssetUrlString } from '@!/Util'
import { TitleList } from './TitleList'
import { BookTitleInfo } from './BookTitleInfo'
import { SearchForm } from '@!/Store/Common/containers'
import { PrimaryButton } from '@!/components/Button'
import { AddAllVolumeButton } from '@!/Store/Common/components/Cart'

import promotionBannerImage from '@images/point_back_promotion_banner.webp'
import topCashToPointPng from '@images/10000_cash_point_back.png'
import midCashToPointPng from '@images/3000_cash_point_back.png'
import lowCashToPointPng from '@images/1000_cash_point_back.png'
import bookPageCapturePng from '@images/book_page_capture.png'
import cartPurchasePageCapturePng from '@images/cart_purchase_page_capture.png'
import basicPointBackCaluculationPng from '@images/basic_point_back_caluculation.png'
import pointUsageBackCaluculationPng from '@images/point_usage_back_caluculation.png'
import headerCapturePng from '@images/header_capture.png'
import menuCapturePng from '@images/menu_capture.png'
import pointHistoriesPageCapturePng from '@images/point_histories_page_capture.png'
import medal0YenIconPng from '@images/medal_0_yen_icon.png'
import ticket0YenIconPng from '@images/ticket_0_yen_icon.png'
import storeIconPng from '@images/store_icon.png'

export const Event = (props) => {
  const [, setBreadcrumb] = useAtom(breadcrumbAtom)
  const titles = props.params.event

  useEffect(() => {
    setBreadcrumb([{ name: 'マンガBANGブックス', ref: '/' }, { name: 'ポイント還元について' }])
  }, [])

  return (
    <>
      <Heading as="h1">
        <Image alt="全作品対象初回限定90%OFF" src={buildAssetUrlString(promotionBannerImage)} />
      </Heading>
      <Box padding={5} textAlign="center" backgroundColor="bg.secondary">
        <Box paddingY={5}>
          <Text>話題の人気作も</Text>
          <Heading as="h2" fontSize="2xl">
            40%ポイント還元
          </Heading>
        </Box>
        <Box paddingY={5}>
          <SimpleGrid columns={{ base: 2, md: 3 }} spacing="13px">
            {titles.trending_manga.map((title, index) => (
              <Flex key={index}>
                <Box
                  flex="1"
                  backgroundColor="bg.primary"
                  boxShadow="0px 3px 12px rgba(0, 0, 0, 0.06)"
                  borderRadius="lg"
                  p={2}
                  fontFamily="Hiragino Sans"
                  fontWeight="600"
                >
                  <BookTitleInfo title={title} />
                  <Box maxW="208px" m="0 auto">
                    <AddAllVolumeButton bookTitleId={title.id} buttonText="全巻カート" />
                  </Box>
                </Box>
              </Flex>
            ))}
          </SimpleGrid>
          <Text fontSize="sm" textAlign="left" pt={2}>
            ※ 4/16時点の価格と還元ポイントです。実際の価格と還元ポイントは変動している場合がございます。
          </Text>
        </Box>
        <Box paddingY={5}>
          <Text>リニューアル</Text>
          <Heading as="h2" fontSize="2xl">
            キャンペーン情報
          </Heading>
        </Box>
        <Box paddingY={5}>
          <Grid templateColumns="repeat(2, 1fr)" gap={4}>
            <VStack backgroundColor="bg.primary" boxShadow="0px 3px 12px rgba(0, 0, 0, 0.06)" borderRadius="lg" padding={2}>
              <Box fontWeight="bold" color="label.tertiary" fontSize="3xl" borderBottom="solid 1px">
                01
              </Box>
              <Text fontSize="sm" fontWeight="semibold" color="#000000" paddingY={3}>
                購入価格の40%をポイント還元
              </Text>
            </VStack>
            <VStack backgroundColor="bg.primary" boxShadow="0px 3px 12px rgba(0, 0, 0, 0.06)" borderRadius="lg" padding={2}>
              <Box fontWeight="bold" color="label.tertiary" fontSize="3xl" borderBottom="solid 1px">
                02
              </Box>
              <Text fontSize="sm" fontWeight="semibold" color="#000000" paddingY={3}>
                ポイントは1pt=1円(税込)で利用
              </Text>
            </VStack>
            <VStack backgroundColor="bg.primary" boxShadow="0px 3px 12px rgba(0, 0, 0, 0.06)" borderRadius="lg" padding={2}>
              <Box fontWeight="bold" color="label.tertiary" fontSize="3xl" borderBottom="solid 1px">
                03
              </Box>
              <Text fontSize="sm" fontWeight="semibold" color="#000000" paddingY={3}>
                獲得上限や利用上限など制限は一切なし
              </Text>
            </VStack>
            <VStack backgroundColor="bg.primary" boxShadow="0px 3px 12px rgba(0, 0, 0, 0.06)" borderRadius="lg" padding={2}>
              <Box fontWeight="bold" color="label.tertiary" fontSize="3xl" borderBottom="solid 1px">
                04
              </Box>
              <Text fontSize="sm" fontWeight="semibold" color="#000000" paddingY={3}>
                42万作品以上が対象
              </Text>
              <Text fontSize="3xs" color="#000000" alignSelf="flex-start">
                ※ 2024/7/22 時点
              </Text>
            </VStack>
          </Grid>
        </Box>
        <Box paddingY={5}>
          <Text fontSize="3xs" color="#000000" lineHeight="2em">
            欲しかった新刊や気になっていたマンガをまとめ買い!!
          </Text>
          <Heading as="h2" fontSize="2xl">
            買えば買うほどお得!!
          </Heading>
        </Box>
        <Box paddingY={5}>
          <Box backgroundColor="bg.primary" boxShadow="0px 3px 12px rgba(0, 0, 0, 0.06)" borderRadius="lg" padding={4} marginY={2}>
            <Image src={buildAssetUrlString(topCashToPointPng)} width="290px" margin="0 auto" />
          </Box>
          <Box backgroundColor="bg.primary" boxShadow="0px 3px 12px rgba(0, 0, 0, 0.06)" borderRadius="lg" padding={4} marginY={2}>
            <Image src={buildAssetUrlString(midCashToPointPng)} width="290px" margin="0 auto" />
          </Box>
          <Box backgroundColor="bg.primary" boxShadow="0px 3px 12px rgba(0, 0, 0, 0.06)" borderRadius="lg" padding={4} marginY={2}>
            <Image src={buildAssetUrlString(lowCashToPointPng)} width="290px" margin="0 auto" />
          </Box>
        </Box>
      </Box>
      <Box padding={5} textAlign="center" bgGradient="linear(to-b, #FEDC00, #F37E02)">
        <Box paddingY={5}>
          <Heading as="h2" fontSize="2xl">
            対象作品
          </Heading>
        </Box>
        <Box paddingY={2}>
          <Text fontWeight="medium">作品詳細ページまたは巻詳細ページで確認</Text>
        </Box>
        <Box paddingBottom={5}>
          <Image src={buildAssetUrlString(bookPageCapturePng)} width="132px" margin="0 auto" />
        </Box>
        <Box paddingY={2}>
          <Text fontWeight="medium">還元ポイント数は購入ページやカートで確認</Text>
        </Box>
        <Box paddingBottom={5}>
          <Image src={buildAssetUrlString(cartPurchasePageCapturePng)} width="350px" margin="0 auto" />
        </Box>
      </Box>
      <Box padding={5} textAlign="center" backgroundColor="bg.secondary">
        <Box paddingY={5}>
          <Heading as="h2" fontSize="2xl">
            計算方法
          </Heading>
        </Box>
        <Heading as="h3" fontSize="md" paddingY={2}>
          決済金額の
          <Text as="span" color="primary.main">
            税抜き価格
          </Text>
          ×40%
        </Heading>
        <Box paddingY={2} textAlign="left">
          <Text fontSize="3xs" display="inline">
            ※ポイントは
          </Text>
          <Text fontSize="3xs" color="primary.main" display="inline">
            1pt＝1円
          </Text>
          <Text fontSize="3xs" display="inline">
            （税込）としてご利用いただけます
          </Text>
          <Text fontSize="3xs">※決済金額は購入する作品の合計金額からポイント使用分を引いた金額です</Text>
        </Box>
        <Box paddingY={5}>
          <Box backgroundColor="bg.primary" boxShadow="0px 3px 12px rgba(0, 0, 0, 0.06)" borderRadius="lg" padding={4} marginY={2}>
            <Flex alignItems="center">
              <Box
                backgroundColor="black"
                borderRadius="full"
                minWidth="52px"
                minHeight="52px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                padding={2}
                marginRight={3}
              >
                <Text color="label.white" fontWeight="bold">
                  例1
                </Text>
              </Box>
              <Text fontWeight="semibold" textAlign="left">
                550円（税込）の作品を購入する場合
              </Text>
            </Flex>
            <Divider marginY={4} />
            <Image src={buildAssetUrlString(basicPointBackCaluculationPng)} width="221px" margin="0 auto" marginY={6} />
            <Box paddingY={2} textAlign="left">
              <Text fontSize="3xs">※小数点は切り捨てします</Text>
            </Box>
          </Box>
          <Box backgroundColor="bg.primary" boxShadow="0px 3px 12px rgba(0, 0, 0, 0.06)" borderRadius="lg" padding={4} marginY={2}>
            <Flex alignItems="center">
              <Box
                backgroundColor="black"
                borderRadius="full"
                minWidth="52px"
                minHeight="52px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                padding={2}
                marginRight={3}
              >
                <Text color="label.white" fontWeight="bold">
                  例2
                </Text>
              </Box>
              <Text fontWeight="semibold" textAlign="left">
                550円（税込）の作品を100pt使用して購入する場合
              </Text>
            </Flex>
            <Divider marginY={4} />
            <Image src={buildAssetUrlString(pointUsageBackCaluculationPng)} width="319px" margin="0 auto" marginY={6} />
            <Box paddingY={2} textAlign="left">
              <Text fontSize="3xs">※税込み価格を税抜きに計算および税抜き価格に還元率を掛けた時の小数点は切り捨てします</Text>
              <Text fontSize="3xs">※ポイントを使用した分は、還元の対象外です</Text>
            </Box>
          </Box>
          <Box backgroundColor="bg.primary" boxShadow="0px 3px 12px rgba(0, 0, 0, 0.06)" borderRadius="lg" padding={4} marginY={2}>
            <Flex alignItems="center">
              <Box
                backgroundColor="black"
                borderRadius="full"
                minWidth="52px"
                minHeight="52px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                padding={2}
                marginRight={3}
              >
                <Text color="label.white" fontWeight="bold">
                  例3
                </Text>
              </Box>
              <Text fontWeight="semibold" textAlign="left">
                550円（税込）の作品を550pt使用して購入する場合
              </Text>
            </Flex>
            <Divider marginY={4} />
            <Box paddingY={2}>
              <Text fontSize="xs" marginY={2}>
                決済金額が0円となり、ポイントは還元されません
              </Text>
            </Box>
          </Box>
        </Box>
        <Box paddingY={5}>
          <Heading as="h2" fontSize="2xl">
            ポイント有効期限
          </Heading>
        </Box>
        <Box paddingY={2}>
          <Text fontWeight="bold" display="inline">
            ポイント付与日から
          </Text>
          <Text fontWeight="bold" color="primary.main" display="inline">
            30日間
          </Text>
          <Text fontWeight="bold" display="inline">
            です
          </Text>
        </Box>
        <Box paddingY={5}>
          <Box background="linear-gradient(180deg, #FEDA01 0%, #F48303 100%)" boxShadow="0px 3px 12px rgba(0, 0, 0, 0.06)" borderRadius="lg" marginY={2}>
            <Heading as="h3" fontSize="md" padding={3}>
              有効期限確認方法
            </Heading>
            <Box backgroundColor="bg.primary" padding={4} fontSize="xs" fontWeight="semibold" borderBottomLeftRadius="lg" borderBottomRightRadius="lg">
              <Box>①画面左のメニューボタンをクリック</Box>
              <Image src={buildAssetUrlString(headerCapturePng)} width="196px" paddingBottom={1} margin="0 auto" />
              <Divider marginY={4} />
              <Box>②メニューリストを開いたら有効期限をクリック</Box>
              <Image src={buildAssetUrlString(menuCapturePng)} width="196px" paddingBottom={1} margin="0 auto" />
              <Divider marginY={4} />
              <Box>③保有ポイントと有効期限を確認</Box>
              <Image src={buildAssetUrlString(pointHistoriesPageCapturePng)} width="196px" paddingBottom={1} margin="0 auto" />
            </Box>
          </Box>
        </Box>
        <Heading as="h3" fontSize="md" paddingTop={2}>
          ポイントは
        </Heading>
        <Box paddingY={5}>
          <Heading as="h2" fontSize="2xl">
            アプリでも利用できる
          </Heading>
        </Box>
        <Box textAlign="left">
          <Text fontSize="3xs" display="inline">
            ※アプリでポイントをお使いいただくためには、事前にアプリで会員登録の上、マンガBANGブックスでログインが必要です。
          </Text>
        </Box>
        <Box paddingY={5}>
          <Box backgroundColor="bg.primary" boxShadow="0px 3px 12px rgba(0, 0, 0, 0.06)" borderRadius="lg" padding={4} marginY={2}>
            <Box padding={4} fontSize="xs" fontWeight="semibold">
              WEBで貯まったポイントは
              <br />
              マンガBANG！（アプリ）内の
            </Box>
            <Heading as="h3" fontSize="2xl">
              メダル・チケット作品
            </Heading>
            <Box display="flex" justifyContent="center" alignItems="center" paddingY={2}>
              <Image src={buildAssetUrlString(medal0YenIconPng)} width="70px" alt="メダルアイコン" />
              <Image src={buildAssetUrlString(ticket0YenIconPng)} width="70px" alt="チケットアイコン" />
            </Box>
            <Heading as="h3" fontSize="2xl">
              マンガBANGストア
            </Heading>
            <Box display="flex" justifyContent="center" alignItems="center" paddingY={2}>
              <Image src={buildAssetUrlString(storeIconPng)} width="70px" alt="マンガBANGストアアイコン" />
            </Box>
            <Box padding={3} fontSize="xs" fontWeight="semibold">
              でも、1pt=1コインとしてご利用いただけます
            </Box>
          </Box>
        </Box>
        <Box bg="bg.primary" borderRadius="lg" borderWidth={1} textAlign="center">
          <Box p={5}>
            <Heading as="h2" mb={4} fontSize="2xl">
              人気マンガも
              <br />
              <Text as="span" color="primary.main">
                全部40％還元
              </Text>
            </Heading>
            <TitleList titles={titles.popular_manga} />
            <Divider />
          </Box>
          <Box pb={4}>
            <Heading as="h2" mb={4} fontSize="2xl">
              欲しい
              <Text as="span" color="primary.main">
                マンガを検索
              </Text>
            </Heading>
            <SearchForm />
          </Box>
        </Box>
        <Box mt={6} mb={2} p={6} bg="bg.primary" borderRadius="lg" borderWidth={1} textAlign="center">
          <Box fontWeight="600" textAlign="center">
            \ まずはこちら /
          </Box>
          <Box mt={4}>
            <PrimaryButton as="a" href="/guide">
              初めての方へ
            </PrimaryButton>
          </Box>
        </Box>
        <Box paddingY={5}>
          <Heading as="h2" fontSize="2xl">
            注意書き
          </Heading>
        </Box>
        <Box paddingY={3} textAlign="left" fontSize="xs">
          <List spacing={2} styleType="none" paddingLeft={5}>
            <ListItem position="relative" _before={{ content: '"※"', position: 'absolute', left: '-1em' }}>
              <Text>一部還元対象外の雑誌があります。</Text>
            </ListItem>
            <ListItem position="relative" _before={{ content: '"※"', position: 'absolute', left: '-1em' }}>
              <Text>ポイントを利用した分の金額は還元の対象外です。</Text>
            </ListItem>
            <ListItem position="relative" _before={{ content: '"※"', position: 'absolute', left: '-1em' }}>
              <Text>マンガBANGブックスで付与されたポイントは、マンガBANG!（アプリ）の下記コーナーでも1pt=1コインとしてお使いいただけます。</Text>
            </ListItem>
            <ListItem position="relative" _before={{ content: '"①"', position: 'absolute' }}>
              <Text paddingLeft="1em">メダル・チケット作品</Text>
            </ListItem>
            <ListItem position="relative" _before={{ content: '"②"', position: 'absolute' }}>
              <Text paddingLeft="1em">マンガBANGストア</Text>
            </ListItem>
            <ListItem>
              <Text>事前にアプリで会員登録をした後にWEBでのログインが必要です。</Text>
            </ListItem>
            <ListItem position="relative" _before={{ content: '"※"', position: 'absolute', left: '-1em' }}>
              <Text>保有ポイント数及び1日に使用できるポイント数の上限はありません。</Text>
            </ListItem>
            <ListItem position="relative" _before={{ content: '"※"', position: 'absolute', left: '-1em' }}>
              <Text>ポイントの還元率や付与条件、対象作品、キャンペーン期間は予告なく変更になる場合がございます。</Text>
            </ListItem>
            <ListItem position="relative" _before={{ content: '"※"', position: 'absolute', left: '-1em' }}>
              <Text>ポイントの有効期限は30日間となります。</Text>
            </ListItem>
            <ListItem position="relative" _before={{ content: '"※"', position: 'absolute', left: '-1em' }}>
              <Text>ポイントは下記の場合に失効します。</Text>
            </ListItem>
            <ListItem position="relative" _before={{ content: '"①"', position: 'absolute' }}>
              <Text paddingLeft="1em">有効期限が切れた場合</Text>
            </ListItem>
            <ListItem position="relative" _before={{ content: '"②"', position: 'absolute' }}>
              <Text paddingLeft="1em">マンガBANGブックスを退会した場合</Text>
            </ListItem>
            <ListItem position="relative" _before={{ content: '"③"', position: 'absolute' }}>
              <Text paddingLeft="1em">マンガBANG（アプリ）でアカウントを削除した場合</Text>
            </ListItem>
            <ListItem position="relative" _before={{ content: '"※"', position: 'absolute', left: '-1em' }}>
              <Text>失効したポイントはいかなる場合でも再度付与はいたしません。</Text>
            </ListItem>
          </List>
        </Box>
      </Box>
    </>
  )
}
