import { useEffect } from 'react'
import { useAtom } from 'jotai'
import { Box } from '@chakra-ui/react'
import { buildUrlString } from '@!/Util'
import { breadcrumbAtom } from '@!/atoms/breadcrumbAtom'
import { saveBookAccessLog } from '@!/Util/localStorageData'
import { Error404 } from '@!/Errors/components/Error404'
import { CircularProgressModal } from '@!/components/Modal'
import { useBookDetailStatus } from '@!/Store/BookDetail/hooks/useBookDetailStatus'
import BookDetail from '@!/Store/BookDetail/components/BookDetail'

const StoreBookDetail = ({ book, currentTime }) => {
  const [, setBreadcrumb] = useAtom(breadcrumbAtom)
  const { currentBook, isLoading } = useBookDetailStatus(book, currentTime)

  useEffect(() => {
    if (book) {
      saveBookAccessLog(book.volumes[0].distribution_id, book.volumes[0].title_name, book.volumes[0].image_url)
    }
  }, [book])

  useEffect(() => {
    // パンくずのセット
    setBreadcrumb([
      { name: 'マンガBANGブックス', ref: '/' },
      { name: book.genre_name, ref: buildUrlString(`/store/search/category/${book.genre_name}`) },
      { name: book.publisher_name, ref: buildUrlString(`/store/search/publisher/${book.publisher_name}`) },
      { name: book.title_name, ref: buildUrlString('/store/books', { book_title_id: book.title_id }) },
      { name: book.name },
    ])
  }, [])

  if (isLoading) {
    return <CircularProgressModal isOpen={true} />
  }

  return (
    <>
      {currentBook ? (
        <BookDetail currentBook={currentBook} />
      ) : (
        <Box pb={12}>
          <Error404 />
        </Box>
      )}
    </>
  )
}

export default StoreBookDetail
