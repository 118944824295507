import PropTypes from 'prop-types'
import { Box, Image, HStack, VStack, Center, Text } from '@chakra-ui/react'
import { buildAssetUrlString } from '@!/Util/buildAssetUrlString'

import StoreCoinSvg from '@images/store_coin.svg'

export const CurrentCoinInfo = ({ coinCounts }) => (
  <Box>
    <VStack>
      <HStack mb="16px" color="label.primary" fontSize="3xl" fontWeight="bold" spacing={0}>
        <Image mr={2} src={buildAssetUrlString(StoreCoinSvg)} />
        <Box>{coinCounts && (coinCounts.coin_count + coinCounts.bonus_coin_count).toLocaleString()}</Box>
      </HStack>
      <Box px="16px" w="100%" boxSizing="border-box">
        <Box p="8px" fontSize="xs" fontWeight="bold" lineHeight="20px" border="solid 1px #DCDCE1">
          <Center>マンガBANG（アプリ）専用コイン</Center>
          <Center>
            合計：
            <Text as="span" color="label.accent">
              {coinCounts && (coinCounts.unusable_coin_count + coinCounts.unusable_bonus_coin_count).toLocaleString()}
            </Text>
          </Center>
        </Box>
        <VStack m="12px 0 16px 0" color="label.secaondary" fontSize="3xs" fontWeight="300" lineHeight="16.5px" alignItems="flex-start">
          <Box pl="1rem" style={{ textIndent: '-1rem' }}>
            ※ マンガBANGブックスでは、マンガBANG（アプリ）で購入したコインはご利用いただけません。
          </Box>
          <Box pl="1rem" style={{ textIndent: '-1rem' }}>
            ※ マンガBANGブックスでご購入されたコインは、マンガBANG（アプリ）でもご利用頂けます。
          </Box>
        </VStack>
      </Box>
    </VStack>
  </Box>
)

CurrentCoinInfo.propTypes = {
  coinCounts: PropTypes.shape({
    coin_count: PropTypes.number.isRequired,
    bonus_coin_count: PropTypes.number.isRequired,
    unusable_coin_count: PropTypes.number.isRequired,
    unusable_bonus_coin_count: PropTypes.number.isRequired,
  }),
}
