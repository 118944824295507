import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'
import { saveCardApi } from '@!/Api/payment'

export const usePaymentSaveCard = () => {
  const saveCardMutation = useMutation({
    mutationFn: ({ token, isUpdate }) => saveCardApi({ token, isUpdate }),
  })

  const getErrorMessage = useCallback((error) => {
    const errorMessage = error?.data?.error || error?.message || 'エラーが発生しました。'

    switch (errorMessage) {
      case 'fraudulent_registration':
        return '登録回数が上限に達しました。時間をおいて再度お試しください。'
      case 'not_registered':
      case 'token_expire':
        return '登録に失敗しました。再度入力をお願いします。'
      case 'card_info':
        return '登録に失敗しました。入力内容をご確認ください。'
      default:
        return errorMessage
    }
  }, [])

  return [saveCardMutation, getErrorMessage]
}
