import { Box, Image, Text, VStack } from '@chakra-ui/react'
import LazyLoad from 'react-lazyload'

import { buildAssetUrlString } from '@!/Util'

import AbjMarkImage from '@images/white_abj_mark.webp'

export const AbjBox = () => {
  return (
    <Box p={3} maxW="343px" border="1px" borderColor="#888" borderRadius="lg" my={2}>
      <VStack>
        <LazyLoad offset={100} once>
          <Image width={55} src={buildAssetUrlString(AbjMarkImage)} />
        </LazyLoad>
        <Text color="white" fontSize="2xs">
          ＡＢＪマークは、この電子書店・電子書籍配信サービスが、著作権者からコンテンツ使用許諾を得た正規版配信サービスであることを示す登録商標（登録番号
          第６０９１７１３号）です。
        </Text>
      </VStack>
    </Box>
  )
}
