import { PropTypes } from 'prop-types'
import { Link, List, ListItem } from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'

export const OpenAppStore = ({ handleOpenApp }) => {
  return (
    <List spacing={0} px={3} fontSize="2xs" maxWidth="100%" width="327px" sx={{ ul: { display: 'inline-block' } }}>
      <ListItem display="inline">
        <Link onClick={handleOpenApp}>アプリを開く</Link>
        <ChevronRightIcon mx="2px" />
      </ListItem>
      <ListItem display="inline">
        マイページ
        <ChevronRightIcon mx="2px" />
      </ListItem>
      <ListItem display="inline">新規会員登録</ListItem>
    </List>
  )
}

OpenAppStore.propTypes = {
  handleOpenApp: PropTypes.func.isRequired,
}
