import { useMemo } from 'react'
import { IoIosTimer } from 'react-icons/io'
import { Box, Heading, SimpleGrid, HStack, Text, VStack } from '@chakra-ui/react'
import { useTabs } from '@!/Hooks'
import { Book } from '@!/Store/Common/components/ListItem'
import { BuyNowButton } from '@!/Store/Common/components/BookList/Button'
import { CartIconButton } from '@!/Store/Common/components/Cart'

const NewBooksList = ({ newBooks, tabName }) => {
  const { tabs } = useTabs(tabName, '/store/new_books', true)
  const genreMapping = {
    boy: 'man',
    man: 'man',
    girl: 'woman',
    lady: 'woman',
    tl: 'tl',
    bl: 'bl',
    adult: 'adult',
  }
  const genreLabels = useMemo(() => Object.fromEntries(tabs.map((tab) => [tab.name, tab.label])), [tabs])

  return (
    <>
      {newBooks.map(({ publish_start_date, books }) => (
        <Box key={publish_start_date} mb={5}>
          <HStack spacing={0} alignItems="center">
            <Box width="3px" height="20px" bg="label.accent" mr={2} />
            <IoIosTimer size={18} strokeWidth="18px" />
            <Heading as="h2" fontSize="lg" ml={0.5} fontWeight="semibold">
              {publish_start_date}配信
            </Heading>
          </HStack>
          <SimpleGrid columns={{ base: 3, md: 7 }} spacing={4} py={3} boxSizing="border-box">
            {books.map((book, index) => (
              <VStack spacing={0} key={index}>
                <Book title={book.full_name} distributionId={book.distribution_id} imageUrl={book.image_url} w="100%" mr={0} />
                {/* 全ジャンルタブが選択されてる場合のみジャンル名を表示 */}
                {!tabName && (
                  <Text fontSize="3xs" color="label.tertiary" textAlign="left" w="100%">
                    {genreLabels[genreMapping[book.genre_name]]}
                  </Text>
                )}
                <HStack spacing={0} width="100%" height="28px" mt={1}>
                  <BuyNowButton volume={book} fontSize="3xs" mr={1}>
                    今すぐ購入
                  </BuyNowButton>
                  <CartIconButton detail={book} iconOnly={true} width="28px" height="100%" color="label.primary" />
                </HStack>
              </VStack>
            ))}
          </SimpleGrid>
        </Box>
      ))}
    </>
  )
}

export default NewBooksList
