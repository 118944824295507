import { useQuery } from '@tanstack/react-query'

import { getAnnouncemetsApi } from '@!/Api/announcement'
import { announcementsKeys } from '@!/Api/queryKeys'

export function useAnnouncements() {
  return useQuery({
    queryKey: announcementsKeys.announcements,
    queryFn: async () => {
      return await getAnnouncemetsApi()
    },
  })
}
