import axios from '@!/lib/axios'
import { getCsrfToken } from '@!/Util/postRequest'

export const searchCardApi = async () => {
  return await axios.post('/api/v1/payment/search_card', null, {
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': getCsrfToken(),
    },
  })
}

export const saveMemberApi = async () => {
  return await axios.post('/api/v1/payment/save_member', null, {
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': getCsrfToken(),
    },
  })
}

export const saveCardApi = async ({ token, isUpdate }) => {
  return await axios.post(
    '/api/v1/payment/save_card.json',
    { token: token, is_update: isUpdate },
    {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': getCsrfToken(),
      },
    },
  )
}

export const execPurchaseApi = async ({ listIndex, apiTime }) => {
  return await axios.post(
    '/api/v1/payment/exec_purchase',
    {
      list_index: listIndex,
      current_time: apiTime,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': getCsrfToken(),
      },
    },
  )
}

export const execPurchaseCarrierApi = async ({ listIndex, apiTime, carrier }) => {
  return await axios.post(
    '/api/v1/payment/exec_purchase_carrier',
    {
      list_index: listIndex,
      current_time: apiTime,
      carrier: carrier,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': getCsrfToken(),
      },
    },
  )
}

export const execPurchasePaypayApi = async ({ listIndex, apiTime }) => {
  return await axios.post(
    '/api/v1/payment/exec_purchase_paypay',
    {
      list_index: listIndex,
      current_time: apiTime,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': getCsrfToken(),
      },
    },
  )
}
