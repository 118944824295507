import { useMutation } from '@tanstack/react-query'
import { postInquiryApi } from '@!/Api/inquiry'

export const useInquirySubmit = () => {
  return useMutation({
    mutationFn: (data) =>
      postInquiryApi({
        ...data,
        userAgent: navigator.userAgent,
      }),
  })
}
