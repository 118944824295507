import { useQuery } from '@tanstack/react-query'
import { pointBackMeasureApi } from '../Api/pointBackMeasure'
import { pointBackMeasureKeys } from '../Api/queryKeys'

export function usePointBackMeasure() {
  return useQuery({
    queryKey: pointBackMeasureKeys.pointBackMeasure,
    queryFn: pointBackMeasureApi,
  })
}
