import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { pointCountApi } from '@!/Api/user'
import { userPointKeys } from '@!/Api/queryKeys'
import { useAppCookies } from '@!/Hooks'

export const usePointCount = (userHash) => {
  const { pointCountsCookie } = useAppCookies()
  const queryInfo = useQuery({
    queryKey: userPointKeys.pointCounts(userHash),
    queryFn: async () => {
      if (userHash) {
        // cookieから取得できた場合はその値を返す
        if (pointCountsCookie) return pointCountsCookie

        // cookieから取得できなかった場合はAPIを実行する
        try {
          return await pointCountApi()
        } catch (error) {
          console.log(error)
        }
      }

      return {}
    },
  })

  return {
    ...queryInfo,
    // 結果をmemo化
    data: useMemo(() => {
      // point_countがある場合はAPIのレスポンスを返す
      if (queryInfo.data?.point_count) {
        return Number(queryInfo.data.point_count) || 0
      }
      // cookieの場合はdataに直接入っている
      return Number(queryInfo.data) || 0
    }, [queryInfo.data]),
  }
}
