import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Image } from '@chakra-ui/react'
import { buildAssetUrlString } from '@!/Util'
import SecurityCodeHelpPng from '@images/security_code_help.webp'

export const SecurityCodePopupModal = ({ isOpen, onClose }) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>セキュリティコードについて</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        セキュリティコードは、クレジットカードの裏面に記載されている3桁または4桁の数字です。
        <Image src={buildAssetUrlString(SecurityCodeHelpPng)} />
      </ModalBody>
    </ModalContent>
  </Modal>
)
