import { useState } from 'react'
import { HStack, Box, Text, Heading, Link, Divider, Stack } from '@chakra-ui/react'
import { useAllPurchased } from '@!/Hooks'
import { BookExpandedInfo } from '@!/Store/Common/components/BookExpandedInfo'
import { AddAllVolumeButton, CartButton } from '@!/Store/Common/components/Cart'
import { CoverImage, PointBackCountLabel, PriceLabel } from '@!/Store/Common/components'
import { buildUrlString } from '@!/Util'
import { PointBackTag } from '@!/components/Label'
import { PrimaryActionButton, SampleButton } from '@!/Store/Common/components/BookList/Button'
import { AuthorList } from '@!/components/List'

export const BookInfo = ({ book }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const handleReadMoreClick = () => setIsExpanded(true)
  // 全巻購入チェック
  const isAllVolumesPurchased = useAllPurchased(book.volumes)

  return (
    <>
      <Box p={4} pb={0}>
        <HStack alignItems="start" spacing={0} mb={3}>
          <Box mr={4}>
            <CoverImage src={book.image_url} alt={book.full_name} objectFit="contain" width="100px" />
            {book.point_back_count > 0 && <PointBackTag />}
          </Box>
          <Stack spacing={0} w="100%">
            <Heading as="h1" size="sm">
              {book.title_name}({book.volume})
            </Heading>
            <Box w="100%">
              {book.author_name && (
                <Box mt={3}>
                  <Text fontSize="2xs">
                    著者：
                    <AuthorList authors={book.author_name} />
                  </Text>
                </Box>
              )}
              {book.publisher_name && (
                <Box mt={1}>
                  <Text fontSize="2xs">
                    出版社：
                    <Link textDecoration="none" href={`${buildUrlString(`/store/search/publisher/${book.publisher_name}`)}`}>
                      {book.publisher_name}
                    </Link>
                  </Text>
                </Box>
              )}
              {!book.purchased && (
                <Stack spacing={0} mt={2}>
                  <PriceLabel price={book.price} properPrice={book.proper_price} />
                  <PointBackCountLabel pointBackCount={book.point_back_count} />
                </Stack>
              )}
            </Box>
          </Stack>
        </HStack>
        <Box my={3}>
          {!book.purchased && (
            <HStack spacing={0} mb={3}>
              <Box w="50%" mr={2}>
                <SampleButton
                  distributionId={book.distribution_id}
                  sampleBookId={book.sample_book_id}
                  rating={book.rating}
                  price={book.price}
                  isPurchased={book.purchased}
                />
              </Box>
              <Box w="50%">
                <CartButton detail={book} />
              </Box>
            </HStack>
          )}
          <Box height="37px">
            <PrimaryActionButton volume={book} />
          </Box>
        </Box>

        <Box>
          {!isExpanded && (
            <>
              <Text fontSize="sm" noOfLines={6}>
                {book.explanation}
              </Text>
              <Box textAlign="right">
                <Link onClick={handleReadMoreClick} textDecoration="none" fontSize="sm" cursor="pointer">
                  もっと見る
                </Link>
              </Box>
              <Divider mt="2" />
            </>
          )}

          {isExpanded && (
            <>
              <Text fontSize="sm">{book.explanation}</Text>
              <BookExpandedInfo book={book} />
            </>
          )}
        </Box>
      </Box>
      <Box p={4} w="100%" textAlign="center" boxSizing="border-box">
        <Heading as="h2" fontSize="md">
          {book.title_name}の一覧(全{book.total_volume}巻)
        </Heading>
        {!isAllVolumesPurchased && !book.is_blacklisted && (
          <Box w="100%" boxSizing="border-box" mt={3}>
            <AddAllVolumeButton bookTitleId={Number(book.title_id)} buttonText="未購入巻をすべてカートに入れる" />
          </Box>
        )}
      </Box>
    </>
  )
}
