import { useMemo } from 'react'
import { PropTypes } from 'prop-types'
import { Box, Text, Link, Image } from '@chakra-ui/react'
import LazyLoad from 'react-lazyload'

import { buildAssetUrlString } from '@!/Util'

import { openApp } from '@!/Util/openApp'
import { getDevice, getOS } from '@!/Util/device'
import AppInstallPng from '@images/app_install.png'

export const AppInstall = (props) => {
  const device = useMemo(() => getDevice(), [])
  const os = useMemo(() => getOS(), [])
  if (device === 'pc') return null

  const handleOpenApp = (e) => {
    e.preventDefault()
    openApp(os)
  }

  const borderStyle = props.showBorder ? '1px dotted #BDBDBD' : '0px'

  return (
    <Box>
      <Box m="12px" p="12px 0" borderTop={borderStyle} borderBottom={borderStyle} fontSize={device == 'pc' ? '22px' : '14px'} textAlign="center">
        {!props.hiddenText && (
          <Box>
            <Text m={0}>「アプリで読む」にはアプリをインストール！</Text>
            <Text m={0}>
              マンガBANGアプリのインストールは
              <Link href="#" onClick={(e) => handleOpenApp(e)}>
                こちら
              </Link>
              から
            </Text>
          </Box>
        )}
        {props.customText && (
          <Box>
            <Text m={0}>{props.customText}</Text>
          </Box>
        )}
        <Link href="#" color="black" onClick={(e) => handleOpenApp(e)}>
          <LazyLoad offset={100} once>
            <Image
              src={buildAssetUrlString(AppInstallPng)}
              display="block"
              width={device == 'pc' ? '540px' : '320px'}
              m="12px auto"
              maxW="100%"
              alt="マンガBANGインストール"
            />
          </LazyLoad>
          {!props.hiddenBottomText && (
            <Box>
              <Text m={0}>アプリ限定で配信中！人気の無料漫画はこちら</Text>
              <Text m={0}>{props.appInstallTitleText}</Text>
            </Box>
          )}
        </Link>
      </Box>
    </Box>
  )
}

AppInstall.propTypes = {
  // showBorderがtrueの箇所は現状無し
  showBorder: PropTypes.bool.isRequired,
  // 以下のパラメータを渡している箇所は現状無し
  hiddenText: PropTypes.bool,
  customText: PropTypes.string,
  hiddenBottomText: PropTypes.bool,
}
