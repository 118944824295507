import { Box, HStack, Text, Divider, Button } from '@chakra-ui/react'
import { PrimaryButton } from '@!/components/Button'
import { PaymentHeader } from '@!/User/Payment/components'

export const PaymentForm = ({ coin, price, newCardToken, currentCard, handleSubmit, onChangeCard }) => {
  return (
    <>
      <PaymentHeader coin={coin} price={price} />
      {!newCardToken && (
        <>
          <HStack w="100%" px="4" py="5" justify="space-between">
            <Text fontWeight="bold">前回使用したカード</Text>
            <Button size="xs" fontSize="2xs" onClick={onChangeCard}>
              他のカードを使う
            </Button>
          </HStack>
          <Divider />
        </>
      )}

      <HStack w="100%" justify="space-between" p="4" fontSize="2xs">
        <Text>クレジットカード番号</Text>
        <Text>{currentCard?.number}</Text>
      </HStack>

      <Box w="100%" p="4">
        <PrimaryButton fontSize="lg" onClick={handleSubmit}>
          購入する
        </PrimaryButton>
      </Box>
    </>
  )
}
