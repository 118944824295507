import { PropTypes } from 'prop-types'

import { Box } from '@chakra-ui/react'

export const PointBackCountLabel = ({ pointBackCount }) => {
  if (pointBackCount === 0 || pointBackCount === null) {
    return null
  }

  return (
    <Box fontSize="2xs" color="label.accent">
      <Box as="span" fontSize="sm" fontWeight="semibold" mr={1}>
        {pointBackCount}
      </Box>
      pt 還元
    </Box>
  )
}

PointBackCountLabel.propType = {
  pointBackCount: PropTypes.number.isRequired,
}
