import { atom } from 'jotai'

// 販売中のコイン一覧
export const paymentListAtom = atom([])
paymentListAtom.debugLabel = 'paymentList'

// GMO TOKEN API URL
export const tokenApiUrlAtom = atom('')
tokenApiUrlAtom.debugLabel = 'tokenApiUrl'

// GMO APIで使用するshopId
export const shopIdAtom = atom('')
shopIdAtom.debugLabel = 'shopId'

// 選択中のコイン一覧のindexを保存している
export const listIndexAtom = atom(-1)
listIndexAtom.debugLabel = 'listIndex'

// ユーザーが入力したカード情報を保存
export const cardInfoAtom = atom(null)
cardInfoAtom.debugLabel = 'cardInfo'

// コイン一覧からコインを選択したときにsearch_card APIを呼んで取得している
export const apiTimeAtom = atom('')
apiTimeAtom.debugLabel = 'apiTime'

// 新規カード登録情報
export const newCardInfoAtom = atom({ number: null })
newCardInfoAtom.debugLabel = 'newCardInfo'

// 新規カード登録時のTOKEN
export const newCardTokenAtom = atom(null)
newCardTokenAtom.debugLabel = 'newCardToken'

// GMO決済時のaccessId
export const accessIdAtom = atom('')
accessIdAtom.debugLabel = 'accessId'

// GMO決済時のaccessToken
export const accessTokenAtom = atom('')
accessTokenAtom.debugLabel = 'accessToken'

// GMO決済時の支払い方法
export const payTypeAtom = atom('')
payTypeAtom.debugLabel = 'payType'

// GMO決済時の決済開始URL
export const payStartUrlAtom = atom('')
payStartUrlAtom.debugLabel = 'payStartUrl'

export const initPaymentAtom = atom(null, (get, set) => {
  set(newCardInfoAtom, { number: null })
  set(newCardTokenAtom, null)
  set(listIndexAtom, -1)
})
