import { useEffect, useState, useMemo } from 'react'
import { VStack } from '@chakra-ui/react'
import { useAtom } from 'jotai'
import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { CircularProgressModal, ConfirmPopupModal } from '@!/components/Modal'
import { useSearchCard } from '@!/Hooks/useSearchCard'
import { execPurchaseCarrierApi, execPurchasePaypayApi } from '@!/Api/payment'
import { isBlank } from '@!/Util'
import { paymentListAtom, listIndexAtom, cardInfoAtom, apiTimeAtom, accessIdAtom, accessTokenAtom, payStartUrlAtom, payTypeAtom } from '@!/atoms/paymentAtom'
import { useValidListItemCheck } from '@!/User/Payment/Hooks/useValidListItemCheck'
import { userHashAtom } from '@!/atoms/userInfoAtom'
import { PageBackLink, PaymentHeader, PaymentMethodList } from '@!/User/Payment/components'

export const PaymentSelect = () => {
  const navigate = useNavigate()
  const [listIndex, setListIndex] = useAtom(listIndexAtom)
  const [paymentList] = useAtom(paymentListAtom)
  const [cardInfo] = useAtom(cardInfoAtom)
  const [apiTime] = useAtom(apiTimeAtom)
  const [accessId, setAccessId] = useAtom(accessIdAtom)
  const [accessToken, setAccessToken] = useAtom(accessTokenAtom)
  const [, setPayStartUrl] = useAtom(payStartUrlAtom)
  const [, setPayType] = useAtom(payTypeAtom)

  const [isProcessing, setIsProcessing] = useState(true)
  const [apiError, setApiError] = useState(null)

  const [isInValidPaymentListItem] = useValidListItemCheck()
  const coin = useMemo(() => {
    if (isInValidPaymentListItem) return 0
    return paymentList[listIndex][0]
  }, [isInValidPaymentListItem, paymentList, listIndex])
  const price = useMemo(() => {
    if (isInValidPaymentListItem) return 0
    return paymentList[listIndex][1]
  }, [isInValidPaymentListItem, paymentList, listIndex])

  const [userHash] = useAtom(userHashAtom)
  const [searchCardMutation, cardInfoError] = useSearchCard(userHash)

  // キャリア決済API
  const execPurchaseCarrierMutation = useMutation({
    mutationFn: (params) => execPurchaseCarrierApi(params),
    onSuccess: (data) => {
      setAccessId(data.access_id)
      setAccessToken(data.token)
      setPayStartUrl(data.start_url)
      setIsProcessing(false)
    },
    onError: (error) => {
      if (error.data?.error) {
        setApiError(error.data.error)
      } else {
        setApiError('etc_error')
      }
      setIsProcessing(false)
    },
  })

  // PayPay決済API
  const execPurchasePaypayMutation = useMutation({
    mutationFn: (params) => execPurchasePaypayApi(params),
    onSuccess: (data) => {
      setAccessId(data.access_id)
      setAccessToken(data.token)
      setPayStartUrl(data.start_url)
      setIsProcessing(false)
    },
    onError: (error) => {
      if (error.data?.error) {
        setApiError(error.data.error)
      } else {
        setApiError('etc_error')
      }
      setIsProcessing(false)
    },
  })

  useEffect(() => {
    setIsProcessing(searchCardMutation.isPending)
  }, [searchCardMutation.isPending])

  useEffect(() => {
    if (cardInfoError) {
      setApiError(cardInfoError)
    }
  }, [cardInfoError])

  const handlePurchase = (payType) => {
    if (isProcessing) return

    setPayType(payType)

    if (payType === 'paypay') {
      execPurchasePaypayMutation.mutate({ listIndex: listIndex, apiTime: apiTime })
    } else {
      execPurchaseCarrierMutation.mutate({ listIndex: listIndex, apiTime: apiTime, carrier: payType })
    }
  }

  useEffect(() => {
    if (isProcessing || !accessId || !accessToken || apiError) {
      return
    }

    navigate('/redirect')
  }, [isProcessing, accessId, accessToken, apiError])

  const pageBack = (e) => {
    e.preventDefault()
    setListIndex(-1)
    navigate(-1)
  }

  const handleDialog = () => {
    setListIndex(-1)
    location.href = '/user/payment'
  }

  const creditCardLink = isBlank(cardInfo) ? '/new_card' : '/confirm'

  return (
    <VStack maxW="600px" m="auto">
      <PaymentHeader coin={coin} price={price} />
      <PageBackLink pageBack={pageBack} />
      <PaymentMethodList creditCardLink={creditCardLink} handlePurchase={handlePurchase} />
      <CircularProgressModal isOpen={isProcessing} />
      <ConfirmPopupModal handlePopup={() => handleDialog()} text="エラーが発生しました。" isOpen={!!apiError} />
    </VStack>
  )
}

export default PaymentSelect
