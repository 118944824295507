import { VStack, Box, Link, HStack, Icon, Image } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'
import { buildAssetUrlString } from '@!/Util'
import { FaCheck } from 'react-icons/fa6'
import CardImage from '@images/card.webp'
import DocomoImage from '@images/docomo.webp'
import AuImage from '@images/au.webp'
import SoftbankImage from '@images/softbank.webp'
import PaypayImage from '@images/paypay.webp'

const PaymentMethodItem = ({ name, image, link, onClick }) => (
  <Box w="100%" borderRadius="xl" borderY="1px" borderColor="gray.200" boxShadow="md" py="3" px="4" mb="3">
    {link ? (
      <RouterLink to={link}>
        <PaymentMethodContent image={image} />
      </RouterLink>
    ) : (
      <Link onClick={() => onClick(name)}>
        <PaymentMethodContent image={image} />
      </Link>
    )}
  </Box>
)

const PaymentMethodContent = ({ image }) => (
  <HStack>
    <Icon as={FaCheck} color="gray.400" w="6%" h="100%" />
    <Image src={image} w="94%" />
  </HStack>
)

export const PaymentMethodList = ({ creditCardLink, handlePurchase }) => {
  const paymentMethods = [
    { name: 'credit_card', image: CardImage, link: creditCardLink },
    { name: 'docomo', image: DocomoImage },
    { name: 'au', image: AuImage },
    { name: 'softbank', image: SoftbankImage },
    { name: 'paypay', image: PaypayImage },
  ]

  return (
    <Box w="100%">
      <Box bg="gray.100" p="4" fontWeight="bold">
        購入方法を選択してください
      </Box>
      <VStack p="4" spacing="0">
        {paymentMethods.map((method) => (
          <PaymentMethodItem key={method.name} name={method.name} image={buildAssetUrlString(method.image)} link={method.link} onClick={handlePurchase} />
        ))}
      </VStack>
    </Box>
  )
}
