import { useEffect } from 'react'

import { Box, Flex, Link } from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'

import { NormalButton, PrimaryButton } from '@!/components/Button'
import { AddCartIcon } from '@!/components/Icon'
import PurchaseSummary from '@!/Store/Common/components/PurchaseSummary'
import CampaignPointSummary from '@!/Store/Common/components/CampaignPointSummary'
import CartItem from '@!/Store/Cart/components/CartItem'
import { useAtom } from 'jotai'
import { breadcrumbAtom } from '@!/atoms/breadcrumbAtom'
import { isBlank } from '@!/Util'

const Cart = ({ books, cartCount, pointBackMeasure, goToPurchase, handleRemoveCartItems, totalPrice, pointBack }) => {
  const navigate = useNavigate()
  const pageBack = (event) => {
    event.preventDefault()
    navigate(-1)
  }

  const [, setBreadcrumb] = useAtom(breadcrumbAtom)

  useEffect(() => {
    setBreadcrumb([{ name: 'マンガBANGブックス', ref: '/' }, { name: 'カート' }])
  }, [])

  return (
    <Box fontSize="xs">
      <Box maxW="800px" m="0 auto" p="16px" bg="#f3f3f8">
        <Box p="16px" bg="white" border="1px solid #e0e0e0" borderRadius="4px" mb="10px">
          {isBlank(books) ? (
            <Box textAlign="center" color="#aaaaaa" py="25px" border="none">
              カートは空です
            </Box>
          ) : (
            <CartItem books={books} handleRemoveCartItems={handleRemoveCartItems} />
          )}
        </Box>
      </Box>
      <Box w="100%" bg="white" border="1px solid #e0e0e0" position="sticky" bottom="0px">
        <Box p="8px 16px" maxW="800px" m="0 auto">
          <PurchaseSummary totalPrice={totalPrice} />
          {!isBlank(pointBackMeasure) && <CampaignPointSummary pointBack={pointBack} pointBackRate={pointBackMeasure.point_back_rate} />}
          <Flex textAlign="center" mt="8px" justify="space-between">
            <NormalButton
              flex="2"
              width="86px"
              height="37px"
              onClick={() => handleRemoveCartItems(books.map((book) => book.distribution_id))}
              isDisabled={isBlank(books)}
            >
              全て削除
            </NormalButton>
            <PrimaryButton
              flex="8"
              ml="9px"
              height="37px"
              fontSize="sm"
              rightIcon={<AddCartIcon width="22px" height="24px" />}
              onClick={goToPurchase}
              isDisabled={isBlank(books)}
            >
              購入手続きへ({cartCount}個の商品)
            </PrimaryButton>
          </Flex>
          <Box textAlign="center" mt="10px">
            <Link href="#" onClick={pageBack}>
              {'元のページへ戻る'}
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

Cart.propTypes = {
  books: PropTypes.array.isRequired,
  cartCount: PropTypes.number.isRequired,
  pointBackMeasure: PropTypes.object.isRequired,
  goToPurchase: PropTypes.func.isRequired,
  handleRemoveCartItems: PropTypes.func.isRequired,
  totalPrice: PropTypes.number.isRequired,
}

export default Cart
