import { AppProvider } from '@!/providers'
import Bookshelf from '@!/User/Bookshelf/containers/Bookshelf'
import { useAppSettings } from '@!/Hooks/useAppSettings'

const BookshelfApp = () => {
  const jotaiStore = useAppSettings({ isSaveLastUrl: false })

  return (
    <AppProvider jotaiStore={jotaiStore} style={{ height: '100%' }}>
      <Bookshelf />
    </AppProvider>
  )
}

export default BookshelfApp
