import { useQuery } from '@tanstack/react-query'

import { getCartItemsApi } from '../Api/cart'
import { cartItemsKeys } from '../Api/queryKeys'

import { getCartItemsNotLoggedIn } from '../Util/localStorageData'

export function useCartItems(userHash) {
  const cartItemsKey = userHash ? cartItemsKeys.cartItemsLoggedIn : cartItemsKeys.cartItemsNotLoggedIn
  return useQuery({
    queryKey: cartItemsKey(userHash),
    queryFn: async () => {
      if (userHash) {
        return await getCartItemsApi()
      } else {
        return getCartItemsNotLoggedIn()
      }
    },
    refetchOnWindowFocus: false,
  })
}
