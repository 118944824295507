import React from 'react'
import LazyLoad from 'react-lazyload'
import { CoverImage } from '@!/Store/Common/components'
import { Box, SimpleGrid, Text } from '@chakra-ui/react'

// サムネイル表示
export default function BookshelfItems({ books, handleClickBook }) {
  return (
    <LazyLoad offset={100} once>
      <SimpleGrid columns={{ base: 3, md: 6 }} spacing={4}>
        {books.map((book) => (
          <Box key={book.distribution_id} cursor="pointer" onClick={() => handleClickBook(book.distribution_id)}>
            <Box>
              <CoverImage width="100%" src={book.image_url} alt={book.title_name} />
              <Box fontSize="3xs" color="label.primary" pt="5px">
                <Text whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                  {book.title_name}
                </Text>
              </Box>
            </Box>
          </Box>
        ))}
      </SimpleGrid>
    </LazyLoad>
  )
}
